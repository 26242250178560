import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import "../global.css"
import "./style.css"
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';                                 // icons
import { Card } from 'primereact/card';
import { useNavigate } from "react-router-dom";
import { useApiConnector, useSecureApiConnector } from '../utils/ApiConnector';
// import { useToast } from '../utils/Properties';
import { useCookies } from "react-cookie";
import { Controller, useForm } from "react-hook-form";
import background from '../assets/bg-01.jpeg'
import '../../node_modules/primeflex/primeflex.css';
import { usePageWaiting, useToast } from '../utils/Properties';
import { Divider } from "primereact/divider";
import { isStrongPassword, StrongPasswordValidator } from "../utils/StrongPasswordVelidator";





// interface SignInProps {
//     onSubmit: (email: string, password: string) => void;
// }

function SignIn() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [needChPasswd, setNeedChPasswd] = useState(false);
    const navigate = useNavigate();
    const apiInstance = useSecureApiConnector()
    const [cookies, setCookie, removeCookie] = useCookies(["api-token-admin", "need-chpasswd-admin", "token-expires-admin"])
    // const [successToast, errorToast, ,] = useToast();
    const [forgetPasswd, setForgetPasswd] = useState(false);
    const setPageWaiting = usePageWaiting();
    const [successToast, errorToast, ,] = useToast();

    const defaultValues = {
        email: '',
        password: '',
        passwordAgain: '',
    }

    const { control, watch, formState: { errors }, handleSubmit, getValues, reset } = useForm({ defaultValues });

    const onSubmit = (data: any) => {
        checkCresential(data.email, data.password)
        // navigate("/tenent-management");
    };

    const checkCresential = async (email: string, password: string) => {
        setPageWaiting(true)
        await apiInstance.authentication.checkCredentialAdminTenent({
            email: email,
            password: password
        })
            .then(res => res.data)
            .then(res => {

                setCookie("api-token-admin", res.access_token, {
                    path: "/",
                    expires: new Date(res.access_token_expires!)
                })
                setCookie("token-expires-admin", res.access_token_expires!, {
                    path: "/",
                    expires: new Date(res.access_token_expires!)
                })
                // setCookie("need-chpasswd-admin", res.need_chpasswd, {
                //     path: "/",
                //     expires: new Date(res.access_token_expires!)
                // })
                setPageWaiting(false)
                successToast('your credentials are logged in');
                reset();
                console.log(res.need_chpasswd);

                if (res.need_chpasswd) {
                    setNeedChPasswd(true)
                } else {
                    navigate("/dashboard")
                }
            })
            .catch((res) => {
                removeCookie("api-token-admin")
                // removeCookie("need-chpasswd-admin")
                removeCookie("token-expires-admin")
                setPageWaiting(false)
                if (!!res.error) errorToast(res.error.msg);
                else errorToast("Sign in failed");
            })
    }

    const chpasswd = (data: any) => {
        setPageWaiting(true)
        apiInstance.admin.bringYourOwnPasswordUserAdmin({
            new_password: data.password,
        })
            .then(() => {

                successToast('password changed');
                removeCookie("api-token-admin")
                removeCookie("need-chpasswd-admin")
                setNeedChPasswd(false)
                reset();
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast('change password failed');
                setPageWaiting(true)
            })

    };


    return (
        <>


            <div className="grid grid-nogutter p-0 h-screen bg-bluegray-50">
                <div className="col-7 overflow-hidden p-0 hidden lg:flex">
                    <img
                        src={require("../assets/bg-01.jpeg")}
                        className="relative ml-auto block w-full h-full"
                        style={{
                            padding: 0,
                            clipPath: "polygon(0 0, 55% 0, 100% 100%, 45% 100%)",
                            objectFit: 'cover'
                        }}

                    />
                </div>
                <div className="col p-8 text-left flex align-items-center justify-content-center">

                    <section style={{ maxWidth: '400px' }}>
                        <span className="block text-6xl gable-text-orange font-bold mb-1">
                            Tenant Management
                        </span>
                        {(needChPasswd && !forgetPasswd) &&
                            <>
                                <form onSubmit={handleSubmit(chpasswd)} className="p-fluid" style={{ maxWidth: '400px' }} >

                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <div className="m-2">
                                            {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span> */}
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />

                                    <Controller name="passwordAgain" control={control} rules={{
                                        required: 'Password Agian is required.',
                                        validate: (passwd) => (getValues("password") == passwd)
                                    }} render={({ field, fieldState }) => (
                                        <div className="m-2">
                                            {/* <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span> */}
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password Again"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />


                                    <Button
                                        label="Set Password"
                                        type="submit"
                                        className="p-button m-2 p-button-rounded"
                                        style={{ background: '#292666' }}
                                        disabled={!(isStrongPassword(watch().password) && watch().password == watch().passwordAgain)}
                                    />


                                </form>
                                <StrongPasswordValidator password={getValues("password")} />
                                <Divider />
                            </>
                        }
                        {(!needChPasswd && !forgetPasswd) &&
                            <form onSubmit={handleSubmit(onSubmit)} className="p-d-flex p-flex-column p-fluid">
                                <div className="p-field">
                                    {/* <label className="font-bold" htmlFor="email">Email address</label> */}
                                    <Controller name="email" control={control}
                                        rules={{
                                            required: 'Email is required.', pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                message: 'Invalid email address. E.g. example@g-able.com'
                                            }
                                        }}
                                        render={({ field, fieldState }) => (
                                            <div className="p-inputgroup m-2">
                                                <span className="p-inputgroup-addon">
                                                    <i className="pi pi-user" />
                                                </span>
                                                <InputText
                                                    id={field.name} {...field}
                                                    className={!!errors.email ? 'p-invalid' : ''}
                                                    placeholder="Email"
                                                />
                                            </div>
                                        )} />
                                </div>
                                <div className="p-field">
                                    {/* <label className="font-bold" htmlFor="password">Password</label> */}
                                    <Controller name="password" control={control} rules={{ required: 'Password is required.' }} render={({ field, fieldState }) => (
                                        <div className="p-inputgroup m-2">
                                            <span className="p-inputgroup-addon">
                                                <i className="pi pi-key" />
                                            </span>
                                            <Password
                                                id={field.name} {...field}
                                                placeholder="Password"
                                                feedback={false}
                                                className={!!errors.password ? 'p-invalid' : ''}
                                                toggleMask
                                            />
                                        </div>
                                    )} />
                                </div>
                                <Button label="Sign In" type="submit" className="p-button m-2" />

                                {(errors.email || errors.password || errors.passwordAgain) &&
                                    <div className="pl-2">
                                        <p className="p-error font-bold">Hint</p>
                                        <p className="m-0 p-error">{errors.email ? <small>{errors.email.message}</small> : <></>}</p>
                                        <p className="m-0 p-error">{errors.password ? <small>{errors.password.message}</small> : <></>}</p>
                                        <p className="m-0 p-error">{errors.passwordAgain ? <small>{errors.passwordAgain.message ? errors.passwordAgain.message : "Password not match"}</small> : <></>}</p>
                                    </div>
                                }
                            </form>
                        }


                    </section>

                </div>
            </div>

        </>
    );
}

export default SignIn;


