import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { WebProperty } from "../../api"
import { usePageWaiting } from "../../utils/Properties"
import "./style.css"
import { Calendar, CalendarValueType } from 'primereact/calendar';
import moment from "moment"
import { Checkbox } from 'primereact/checkbox';
import { Divider } from "antd"
import { FileUpload, FileUploadHandlerParam } from "primereact/fileupload";
import { Tag } from "primereact/tag";
import { Image } from 'primereact/image';

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    data: WebProperty
    setData: Dispatch<SetStateAction<WebProperty>>
    onSubmit: (data: any) => void;
    edit?: boolean
    editTiltle?: string
}

const CreateSystemProp: FC<props> = ({ data, visible, edit, editTiltle, setVisible, refresh, onSubmit }) => {
    const title = "Create System Property"
    const setPageWating = usePageWaiting()
    const defaultValues = {
        name: '',
        value: '',
        started_at: null as CalendarValueType,
        expired_at: null as CalendarValueType,
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });
    const [checked, setChecked] = useState<boolean>(false);
    // MB
    const mb = 1000000
    const maxUpload = 20 * mb
    const fileUploadRef = useRef(null);
    const [totalSize, setTotalSize] = useState(0)
    const getSize = (s: number) => (s / mb)
    const [file, setFile] = useState({} as File)
    const chooseOptions = { icon: '', iconOnly: false, className: ' p-button-rounded p-button-outlined browse-custom' };

    const setData = async () => {
        setPageWating(true)

        setValue('name', data.name);
        setValue('value', data.value);
        setValue('started_at', data.started_at ? new Date(data.started_at) : null);
        setValue('expired_at', data.expired_at ? new Date(data.expired_at) : null);


        setPageWating(false)
    }

    const headerTemplate = (options: any) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const formatedValue = fileUploadRef && fileUploadRef.current ? (fileUploadRef.current as any).formatSize(totalSize) : '0 B';
        return (
            <div className={`${className} header-file-upload`} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                <div className="w-full srelative font-bold text-right"><span className={getSize(totalSize) < getSize(maxUpload) ? "text-green-700" : "text-red-600"}>{formatedValue}</span>/{getSize(maxUpload)}MB</div>
            </div>
        );
    }

    const emptyTemplate = () => {
        return (
            <div className="flex align-items-center flex-column">
                <i className="pi pi-file mt-3 p-5" style={{ 'fontSize': '2.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ color: 'var(--text-color-secondary)' }} className="my-5">Drag and Drop Here</span>
            </div>
        )
    }

    const setValueAnnouncement = () => {

        if (!checked) {
            setValue('name', "announcement");

        } else {
            setValue('name', "");

        }
    }

    const encodeImageToBase64 = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => {
                const base64EncodedString = reader.result as string;
                resolve(base64EncodedString);
            };

            reader.onerror = (error) => {
                reject(error);
            };
        });
    };


    const decodeBase64ToImageFile = (base64String: string, fileName: string) => {
        const binaryString = atob(base64String.split(',')[1]);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        const imageBlob = new Blob([bytes], { type: 'image/png' });
        return new File([imageBlob], fileName, { type: 'image/png' });
    };


    const handleBase64Image = (base64EncodedImage: string, fileName: string) => {
        try {
            const imageFile = decodeBase64ToImageFile(base64EncodedImage, fileName);
            // console.table(imageFile); // จะได้ File object ที่เป็นรูปภาพ
            // นำ File object ไปใช้งานต่อได้ตามต้องการ เช่น อัปโหลดไปยังเซิร์ฟเวอร์
        } catch (error) {
            console.error('Error decoding base64 image:', error);
        }
    };


    const customUploader = async (e: FileUploadHandlerParam) => {
        setFile(e.files[0])

        let base64EncodedImage = await encodeImageToBase64(e.files[0]);
        // console.log(base64EncodedImage); // พิมพ์ค่า base64 ที่ถูกเข้ารหัส
        // เรียกใช้งาน handleBase64Image เพื่อประมวลผล base64
        let imageValue = base64EncodedImage + "@" + file.name

        handleBase64Image(imageValue.split("@")[0], imageValue.split("@")[1]);

        let total = 0
        e.files.forEach(f => {
            total += f.size
        })
        setTotalSize(total)
        if (e.files[0].type === "application/pdf") {
            setValue('value', e.files[0].name + "|" + base64EncodedImage);

        } else {
            setValue('value', base64EncodedImage);

        }


    }

    useEffect(() => {
        if (edit) {
            setData()
        }
    }, [visible])


    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-23rem "
                showHeader={false}
                style={{ minWidth: '32rem' }}
                onHide={() => {
                    setVisible(false)
                    reset()

                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{ marginTop: '2rem' }}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {editTiltle ? editTiltle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            {editTiltle ? editTiltle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    reset()
                                    setChecked(false)
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                {edit !== true ? (<>
                    <div className="field-checkbox" style={{ marginLeft: '1.45rem' }}>
                        <Checkbox inputId="binary" checked={checked} onChange={e => { setValueAnnouncement(); setChecked(e.checked) }} />
                        <label htmlFor="binary">create announcement</label>
                    </div>
                </>) : (<></>)}

                <div className="py-2 px-4 w-full text-v2 font-bold">

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                        <div className="flex">
                            <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Name<span style={{ color: 'red' }}>*</span></p>
                                <Controller name="name" control={control} rules={{
                                    required: 'Name is required.',
                                }} render={({ field }) => (
                                    <InputText id={field.name} {...field} autoFocus
                                        className={`${!!errors.name ? 'p-invalid' : ''} input-border`}
                                    />
                                )} /></div>


                        </div>

                        {checked === false && data.name !== "announcement" && data.name !== "user_manual" ? (<>
                            <div className="flex">
                                <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Value<span style={{ color: 'red' }}>*</span></p>
                                    <Controller name="value" control={control} rules={{
                                        required: 'Value is required.',
                                    }} render={({ field }) => (
                                        <InputText id={field.name} {...field}
                                            className={`${!!errors.name ? 'p-invalid' : ''} input-border`}
                                        />
                                    )} /></div>
                            </div>
                        </>) : (<></>)}

                        <div className="flex">
                            <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Start Date</p>
                                <Controller name="started_at" control={control} render={({ field }) => (
                                    <>

                                        <Calendar className="input-border" value={watch().started_at} onChange={(e) => {
                                            if (e.value) {
                                                setValue("started_at", moment(e.value!.toString()).toDate());
                                            }
                                        }} showTime showSeconds />
                                    </>

                                )} /></div>
                        </div>
                        <div className="flex">
                            <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">expired Date</p>
                                <Controller name="expired_at" control={control} render={({ field }) => (
                                    <>

                                        <Calendar id={field.name} {...field} className="input-border" value={watch().expired_at} onChange={(e) => {
                                            if (e.value) {
                                                setValue("expired_at", moment(e.value!.toString()).toDate());
                                            }
                                        }} showTime showSeconds />
                                    </>

                                )} /></div>
                        </div>

                        {checked || data.name === "announcement" || data.name === "user_manual" ? (<>
                            <div className="flex" style={{ width: '30rem' }}>
                                <div className="flex-initial align-items-center justify-content-center pr-5" style={{ width: '30rem' }}>
                                    <Divider />
                                    <h5 style={{ marginBottom: "1rem" }}>Reference Pictures</h5>
                                    <FileUpload
                                        ref={fileUploadRef}
                                        customUpload
                                        auto
                                        accept="image/*"
                                        maxFileSize={(maxUpload)}
                                        uploadHandler={customUploader}
                                        chooseLabel="Add"
                                        chooseOptions={chooseOptions}
                                        headerTemplate={headerTemplate} emptyTemplate={emptyTemplate}
                                        itemTemplate={(file: any, props) => {

                                            return (
                                                <div className="flex align-items-center flex-wrap" style={{ color: '#A098AE' }}>
                                                    <div className="flex align-items-center" style={{ width: '40%' }}>
                                                        {file.type !== 'application/pdf' &&
                                                            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
                                                        }
                                                        {file.type === 'application/pdf' &&
                                                            <i className="pi pi-file text-2xl"></i>
                                                        }
                                                        <span className="flex flex-column text-left ml-3">
                                                            {file.name}
                                                            <small>{new Date().toLocaleDateString()}</small>
                                                        </span>
                                                    </div>
                                                    <Tag value={props.formatSize} className="py-2 " style={{ color: '#A098AE', background: 'white', marginLeft: '6rem' }} />
                                                    <div
                                                        className="align-items-center justify-content-center "
                                                        style={{ width: "3.5rem" }}
                                                    >
                                                        <img
                                                            className="transition-duration-200 cursor-pointer  hover:bg-red-500"
                                                            src={require("../../assets/Group 32.png")}
                                                            style={{ borderRadius: '20px', width: '30px' }}
                                                            alt="bin"
                                                            onClick={(e) => {
                                                                setTotalSize(totalSize - file.size);
                                                                props.onRemove(e)
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        }}
                                    />
                                </div>
                            </div>

                            {/* <div className="flex">
                                <h5>Preview and Zoom</h5>
                                <Image src={`${imageObject.objectURL}`} alt="Image" width="250" preview />
                            </div> */}


                        </>) : (<></>)}

                        <div>
                            <Button
                                label="Submit"
                                type="submit"
                                className="p-button mt-3 transition-duration-300 hover:bg-indigo-700 add-template"
                                style={{ borderRadius: '30px', width: '194px', background: '#292666', marginBottom: '2rem' }}
                            />
                        </div>

                        {((errors.name) || (errors.value)) &&

                            <div className="pl-2 mt-2">
                                <p className="p-error font-bold m-0">Hint</p>
                                <p className="m-0 p-error">{errors.name ? <small>{errors.name.message}</small> : <></>}</p>
                                <p className="m-0 p-error">{errors.value ? <small>{errors.value.message}</small> : <></>}</p>
                            </div>
                        }


                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default CreateSystemProp;