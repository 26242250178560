import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting } from "../../utils/Properties"
import "./style.css"
import { Timeline } from 'primereact/timeline';
import { Card } from "primereact/card"
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { SupportProgess } from "../../api"
import moment from "moment"

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    data: any
    setData: Dispatch<SetStateAction<any>>
    onSubmit: (data: any) => void;
    titleName?: string;
    fieldName?: string;
    tabName?: string;
    tenant_id?: string;
}

const Progess: FC<props> = ({ data, visible, setData, setVisible, refresh, onSubmit, titleName, fieldName, tabName, tenant_id }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Support Progress"
    const [newDialog, setNewDialog] = useState(false)
    const [progessData, setProgessData] = useState([] as SupportProgess[])
    const [displayProgess, setDisplayProgess] = useState([] as any[])
    const [temp, setTemp] = useState([] as any[])
    const setWaiting = usePageWaiting()
    const events1 = [
        { status: 'open', date: '15/10/2020 10:30', icon: 'pi pi-question-circle', color: '#e32a19' },
        { status: 'acknowledge', date: '15/10/2020 14:00', icon: 'pi pi-eye', color: '#673AB7' },
        { status: 'resolving', date: '15/10/2020 16:15', icon: 'pi pi-search', color: '#ed9521' },
        { status: 'resolved', date: '16/10/2020 10:00', icon: 'pi pi-check', color: '#189df5' },
        { status: 'close', date: '16/10/2020 10:00', icon: 'pi pi-star', color: '#0fdb4d' }
    ];
    const [show, setShow] = useState(false)


    const defaultValues = {
        detail: '',
        stage: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });

    const customizedMarker = (item: any) => {
        return (
            <span className="custom-marker shadow-1" style={{ backgroundColor: item.color }}>
                <i className={item.icon}></i>
            </span>
        );
    };

    const convertToUppercase = (stage: string) => {
        return stage.charAt(0).toUpperCase() + stage.slice(1);
    }

    const customizedContent = (item: SupportProgess) => {
        return (
            <>
                <Card title={convertToUppercase(item.stage!)} subTitle={moment(item.created_at).format('DD/MM/YYYY HH:mm')} style={{ width: "30rem" }}>
                    <pre style={{
                        whiteSpace: "pre-wrap",
                        wordBreak: "break-all",
                        textAlign: "left"
                    }}>{item.detail}</pre>
                </Card>

            </>

        );
    };

    const newProgess = async (progess: any) => {
        setWaiting(true)
        if (progess.stage !== '' && progess.detail !== '') {
            await apiInstance.supportProgess.create({
                detail: progess.detail,
                stage: progess.stage,
                support_id: data.id,
            }).then(res => res.data)
                .then(res => {

                })
            getAllProgess()
            setWaiting(false)
            reset()
            setNewDialog(false)

        }

    }

    const getAllProgess = async () => {
        await apiInstance.supportProgess.getAll(data.id).then(res => res.data)
            .then(res => {
                setProgessData(res.record_list)
            })

    }

    const bindingData = () => {


        events1.forEach((d) => {
            progessData.forEach((m) => {
                if (d.status === m.stage) {
                    m.icon = d.icon;
                    m.color = d.color;
                }
            })
        })
        setDisplayProgess(progessData)


    }

    const checkDropdown = () => {

        const isOpenStageExist = progessData.some(item => item.stage === "open");
        const isAckStageExist = progessData.some(item => item.stage === "acknowledge");
        const isReslovingStageExist = progessData.some(item => item.stage === "resolving");
        const isReslovedStageExist = progessData.some(item => item.stage === "resolved");

        var dropdownList: any[] = []
        if (isOpenStageExist && isAckStageExist === false && isReslovingStageExist === false && isReslovedStageExist === false) {
            dropdownList = [
                { name: 'Acknowledge', value: 'acknowledge' }
            ]
        } else if (isOpenStageExist && isAckStageExist && isReslovingStageExist === false && isReslovedStageExist === false) {
            dropdownList = [
                { name: 'Resolving', value: 'resolving' },
            ]
        } else if (isOpenStageExist && isAckStageExist && isReslovingStageExist && isReslovedStageExist === false) {
            dropdownList = [
                { name: 'Resolving', value: 'resolving' },
                { name: 'Resolved', value: 'resolved' },
            ]
        } else if (isOpenStageExist && isAckStageExist && isReslovingStageExist && isReslovedStageExist) {
            dropdownList = [
                { name: 'Close', value: 'close' }
            ]
        }

        setTemp(dropdownList)
    }

    const Show = () => {
        setShow(true)
    }

    const Disable = () => {
        const isReslovedStageExist = progessData.some(item => item.stage === "close");
        return isReslovedStageExist

    }

    useEffect(() => {

        if (show) {
            getAllProgess()
        }


    }, [show]);

    useEffect(() => {
        setWaiting(true)
        bindingData()
        setWaiting(false)


    }, [progessData]);

    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-23rem "
                showHeader={false}
                style={{ minWidth: '70rem', minHeight: '50rem' }}
                onShow={Show}
                onHide={() => {
                    setVisible(false)
                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{ marginTop: '2rem' }}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            <h2>{titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}</h2>
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            <h2>{titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}</h2>
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {

                                    setProgessData([]);
                                    setShow(false)
                                    setVisible(false);
                                    refresh();
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="w-full text-v1 ml-4" >
                    <Button className="cursor-pointer p-button-info transition-duration-300 hover:bg-blue-200" style={{ borderRadius: "24px" }} onClick={() => {
                        setNewDialog(true)
                    }} disabled={Disable()}>New</Button>
                </div>
                <div className="py-2 px-4 w-full text-v2 font-bold">
                    <Timeline value={displayProgess!} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                </div>
            </Dialog>

            <Dialog header="New Progess" visible={newDialog} style={{ width: '30rem' }} onShow={checkDropdown} onHide={() => { setNewDialog(false); reset() }}>
                <>
                    <form onSubmit={handleSubmit(newProgess)} className="p-fluid ">

                        <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Stages<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="stage" control={control} render={({ field }) => (
                                <>
                                    <Dropdown className={`${watch().stage === "" ? 'p-invalid' : ''} input-border`} value={watch().stage} options={temp}
                                        onChange={(e) => { setValue("stage", e.value) }} optionLabel="name" placeholder="Select a Stage" />
                                </>
                            )} />
                        </div>

                        <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Details<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="detail" control={control} render={({ field }) => (

                                <InputTextarea className={`${watch().detail === "" ? 'p-invalid' : ''} input-border`} value={watch().detail} onChange={(e) => setValue("detail", e.target.value)} rows={5} cols={30} />
                            )} /></div>


                        <div>
                            <Button
                                label="Submit"
                                type="submit"
                                className="p-button mt-3 transition-duration-300 hover:bg-indigo-700 add-template"
                                style={{ borderRadius: '30px', width: '10rem', background: '#292666', marginBottom: '2rem' }}
                            />
                        </div>

                        {((watch().detail === "") || (watch().stage === "")) &&

                            <div className="pl-2 mt-2">
                                <p className="p-error font-bold m-0">Hint</p>
                                <p className="m-0 p-error">{watch().detail === '' ? <small>Details is required</small> : <></>}</p>
                                <p className="m-0 p-error">{watch().stage === '' ? <small>Stage is required.</small> : <></>}</p>
                            </div>
                        }
                    </form>
                </>
            </Dialog>
        </>
    )
}

export default Progess;