import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Fragment, useEffect, useState } from "react";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { ModelsUserAdminTenent } from "../api";
import { useLocation } from "react-router-dom";
import EditUserDialog from "../components/Dialog/EditUserDialog";
import CustomDeleteDialog from "../components/Dialog/CustomDeleteDialog";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import SearchDialog from '../components/SearchDialog';
import AddUserDialog from "../components/Dialog/AddUserDialog";
import { useToast } from "../utils/Properties";
import { Dropdown } from "primereact/dropdown"

const AddUserAdmin = () => {
    const apiInstance = useSecureApiConnector();
    const [data, setData] = useState([] as ModelsUserAdminTenent[]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [objectState, setObjectState] = useState({} as ModelsUserAdminTenent)
    const [dialogEdit, setDialogEdit] = useState(false)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUserAdminTenent)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [objectResetPasswdState, setObjectResetPasswdState] = useState({} as ModelsUserAdminTenent)
    const [dialogDelete2, setDeleteDialog2] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [filter, setFilter] = useState("")
    const [dialogSearch, setSearchDialog] = useState(false)
    const [successToast, errorToast, ,] = useToast();
    const [initData, setInitData] = useState([] as ModelsUserAdminTenent[]);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const fetchData = () => {
        apiInstance.admin
            .getAllAdmin()
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!)
                setData(data.record_list);
                setInitData(data.record_list);
                setTriggerFetch(true);
                setLoading(false);
            });
    };
    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }
    const onSubmit = (data: any) => {
        apiInstance.admin.deleteUser(objectDeleteState.id!.toString())
            .then(() => {
                successToast("user deleted")
                setObjectDeleteState({})
                setDeleteDialog(false)
                refresh()
            })
            .catch(() => {
                errorToast("user delete failed")
                setObjectDeleteState({})
            })
    }
    const onSubmit2 = (data: any) => {
        apiInstance.admin.resetPasswordUser({
            u_id: objectResetPasswdState.id!.toString()
        })
            .then(res => res.data)
            .then((data) => {
                successToast("password has been reset")
                setDeleteDialog2(false)
            })
            .catch(() => {
                errorToast("password reset failed")
                setObjectResetPasswdState({})
            })
    }

    useEffect(() => {
        if (!triggerFetch) {
            setLoading(true)
            fetchData()
        }
    }, [triggerFetch])

    useEffect(() => {
        setLoading(true)
        apiInstance.admin
            .getSearch({
                search: filter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    }, [lazyParams])

    useEffect(() => {
        if (filter === " " && data !== initData) {
            setData(initData);
            setTotalRecords(initData.length)
        } else {
            setLoading(true);

            apiInstance.admin
                .getSearch({
                    search: filter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setData([]);
                    setLoading(false);
                });
        }
    }, [filter]);



    return (
        <>
            <EditUserDialog data={objectState} setData={setObjectState} visible={dialogEdit} setVisible={setDialogEdit} refresh={refresh} />
            <CustomDeleteDialog data={objectResetPasswdState} setData={setObjectResetPasswdState} visible={dialogDelete2} setVisible={setDeleteDialog2} refresh={refresh}
                onSubmit={onSubmit2} meessage={<>Do you want to reset password of &nbsp;{objectResetPasswdState.first_name} {objectResetPasswdState.last_name}&nbsp;?</>} />

            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmit} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteState.first_name} {objectDeleteState.last_name}</b> &nbsp;account? </>} />
            <SearchDialog visible={dialogSearch} setVisible={setSearchDialog} filter={filter} setFilter={setFilter} />
            <AddUserDialog visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                <Card className="card-pad" title="User Admin Management">
                    <div className='w-full relative' style={{ height: "48px", marginBottom: "1rem" }}>
                        <Button
                            icon="mdi mdi-plus-circle-outline"
                            // onClick={(event: any) => menu.current.toggle(event)}
                            onClick={() => setDialogAdd(true)}
                            style={{ left: '0rem', top: '5px' }}
                            className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                            aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add User</Button>
                        <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                            <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" />
                            <i className="pi pi-search gable-text-dark-blue" />

                        </span>
                        <Button
                            icon="mdi mdi-magnify"
                            style={{ right: '1rem', top: '5px' }}
                            className="absolute p-button-rounded p-button-secondary flex md:hidden "
                            onClick={() => { setSearchDialog(true) }}
                        />
                    </div>
                    <DataTable
                        value={data}
                        lazy
                        loading={loading}
                        paginator
                        responsiveLayout="scroll"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={(e: any) => {
                            setLazyParams(e);
                        }}
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="justify-content-end"
                        tableStyle={{ minWidth: "100%" }}
                    >
                        <Column
                            field="first_name"
                            header="First Name"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column
                            field="last_name"
                            header="Last Name"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column
                            field="customer_email"
                            header="Email"
                            style={{ width: "25%" }}
                        ></Column>
                        <Column header="Status" body={(item: ModelsUserAdminTenent) => (<>
                            <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                        </>)}></Column>
                        <Column className='text-right' body={(item) => (
                            <span className="p-buttonset flex">
                                <div
                                    className="align-items-center justify-content-center  "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        // className={`${issue.id === item.id ? '' : 'transition-duration-200 cursor-pointer  hover:bg-red-500'}`}
                                        className={"transition-duration-200 cursor-pointer  hover:bg-red-500"}
                                        src={require("../assets/Group 43 (1).png")}
                                        style={{ borderRadius: '20px' }}
                                        alt="reset"
                                        onClick={() => {
                                            // if (issue.id !== item.id) {
                                            //     console.log(item);

                                            setObjectResetPasswdState(item)
                                            setDeleteDialog2(true)
                                            // }
                                        }}
                                    />
                                </div>
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        className='transition-duration-200 hover:bg-indigo-700 cursor-pointer'
                                        src={require("../assets/Group 36.png")}
                                        alt="pencil"
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        onClick={() => {
                                            setObjectState(item)
                                            setDialogEdit(true)
                                        }}
                                    />
                                </div>
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        // className={`${issue.id === item.id ? '' : 'transition-duration-200 cursor-pointer  hover:bg-red-500'}`}
                                        className={'transition-duration-200 cursor-pointer  hover:bg-red-500'}
                                        src={require("../assets/Group 32.png")}
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        alt="bin"
                                        onClick={() => {
                                            setObjectDeleteState(item)
                                            setDeleteDialog(true)
                                            // if (issue.id !== item.id) {
                                            //     setDeleteDialog(true)
                                            // }
                                        }}
                                    />
                                </div>
                            </span>

                        )}></Column>
                    </DataTable>
                </Card>
            </div>
        </>
    );
};
export default AddUserAdmin;
