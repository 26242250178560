import { Api } from "../api"
import { useContext, createContext } from "react";
import { useCookies } from "react-cookie";

type ContextProps = {
    instance: Api<unknown>
}

export const ApiContext = createContext<ContextProps | null>(null);

interface ApiPros {
    children: any
}


const ApiProvider: React.FC<ApiPros> = ({ children }) => {
    const apiConnector = new Api({
        baseUrl: process.env.REACT_APP_API_URL,
    })
    return <ApiContext.Provider value={{ instance: apiConnector }}>{children}</ApiContext.Provider>;
}

export default ApiProvider;
export const useApiConnector = () => {
    const apiContext = useContext(ApiContext) as ContextProps
    return apiContext.instance;
}

export const useSecureApiConnector = () => {
    const [cookies] = useCookies(['api-token-admin']);
    const secureApi = new Api({
        baseUrl: process.env.REACT_APP_API_URL,
        baseApiParams: {
            headers: {
                "x-access-token": cookies["api-token-admin"]
            }
        }
    })
    return secureApi;
}
