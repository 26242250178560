import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Dropdown } from "primereact/dropdown"
import "./style.css"
import { useClipboard } from "../../utils/CopyToClipboard"
import { Tooltip } from "primereact/tooltip"
import { RadioButton } from 'primereact/radiobutton';

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const AddUserDialog: FC<props> = ({ visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Add User"
    const [successToast, errorToast, ,] = useToast();
    const setPageWating = usePageWaiting()

    const defaultValues = {
        first_name: '',
        last_name: '',
        customer_email: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });
    const onSubmit = (data: any) => {
        
        setPageWating(true)
        apiInstance.admin.createUser({
            first_name: data.first_name,
            last_name: data.last_name,
            customer_email: data.customer_email,            
        })
            .then(res => res.data)
            .then(data => {
                setPageWating(false)
                successToast("create user successed")
                setVisible(false)
                refresh()
                reset()
            })
            .catch(() => {
                errorToast("create user failed")
            })
    };

    const [directories, setDirectories] = useState([] as ModelsDirectory[])
    const [tempPasswd, setTempPasswd] = useState('')
    const [tempPasswdDialog, setTempPasswdDialog] = useState(false)

    useEffect(() => {
        // if (visible) {
        //     apiInstance.directories.getDirectoryAll()
        //         .then(res => res.data)
        //         .then((data) => {
        //             setDirectories(data.record_list)
        //         })
        // }
    }, [visible])

    const clipboard = useClipboard()
    const copy = (msg: string) => {
        clipboard(msg)
            .then(() => {
                successToast(`Copy ${msg} to clipboard`)
            })
            .catch(() => {
                errorToast(`Copy ${msg} to clipboard failed`)
            })
    }



    return (
        <>
            <Dialog closable={false} draggable={false}
                visible={tempPasswdDialog}
                className="custom-dialog p-0 m-0 w-23rem md:w-30rem dialog-width"
                showHeader={false}
                onHide={() => {
                    setTempPasswd('')
                    setTempPasswdDialog(false)
                    refresh()
                }}
            >
                <div className="w-full gable-bg-gray py-2 px-4">
                    <div className="grid grid-nogutter">
                        <div className="col-10 text-xl hidden md:flex text-white relative">
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setTempPasswd('')
                                    setTempPasswdDialog(false)
                                    refresh()
                                }}
                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="p-5 text-center">
                    <span className="p-3 text-4xl border-1 border-blue-50 border-round-2xl gable-text-gray bg-blue-50">{tempPasswd}</span>

                    <Tooltip target=".copy-btn" />
                    <span
                        onClick={() => {
                            copy(tempPasswd)
                        }}
                        className='ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1'
                        data-pr-tooltip="Copy to clipboard">
                        <i className="mdi mdi-clipboard-text"></i>
                    </span>
                </div>
            </Dialog>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-23rem md:w-30rem dialog-width"
                showHeader={false}
                onHide={() => {
                    setVisible(false)
                    reset()
                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{marginTop:'2rem'}}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            {title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    reset()
                                }}
                            style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-4 w-full text-v2 font-bold">

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">First Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="first_name" control={control} rules={{
                                required: 'First Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field} autoFocus
                                    className={`${!!errors.first_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                        <div className="flex-initial align-items-center justify-content-center px-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Last Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="last_name" control={control} rules={{
                                required: 'Last Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    className={`${!!errors.last_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                    </div>

                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5">
                        <p className="mb-2 mt-2 font-bold text-sm">Email<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="customer_email" control={control} rules={{
                                required: 'Email is required.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address. E.g. example@g-able.com'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}

                                    className={`${!!errors.customer_email ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>                        

                    </div>

                    
                    {(errors.first_name || errors.last_name || errors.customer_email ) &&
                        <div className="pl-2 mt-2">
                            <p className="p-error font-bold m-0">Hint</p>
                            <p className="m-0 p-error">{errors.first_name ? <small>{errors.first_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.last_name ? <small>{errors.last_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.customer_email ? <small>{errors.customer_email.message}</small> : <></>}</p>
                        </div>
                    }

                    <Button
                        label="Submit"
                        type="submit"
                        className="p-button mt-3 transition-duration-300 hover:bg-indigo-700"
                        style={{ borderRadius: '30px', width: '194px', marginLeft: '43rem', background: '#292666' ,marginBottom:'2rem'}}

                    />  
                </form>
                </div>
            </Dialog>
        </>
    )
}

export default AddUserDialog;