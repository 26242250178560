import { Chip } from 'primereact/chip'
import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSecureApiConnector } from '../utils/ApiConnector';
import { useCookies } from "react-cookie";
import { useCheckAdmin, usePageWaiting } from '../utils/Properties';
import TenantManagement from './TenentManagement';


const Dashboard = () => {
    const [processRunningLen, setProcessRunningLen] = useState("")
    const [groupRunningLen, setGroupRunningLen] = useState("")
    const [queueLen, setQueueLen] = useState("")
    const [scheduleLen, setScheduleLen] = useState("")
    const [mounted,] = useState(true)
    const apiInstance = useSecureApiConnector()
    const location = useLocation();
    const [notForbidden, setNotForbidden] = useState(false)
    const [cookies, setCookie, removeCookie] = useCookies(["api-token-admin"])
    const [apiToken, setApiToken] = useState(cookies['api-token-admin'] || '');
    const setWaiting = usePageWaiting()
    const checkAdmin = useCheckAdmin()

    const getMetric = () => { }
    useEffect(() => {
        if (apiToken) {
            getMetric()
            // setWaiting(true)
        }

        setInterval(() => {
            if (location.pathname === "/dashboard") {
                if (apiToken) {
                    getMetric()
                }
            }
        }, 5000)
    }, [mounted])

    const navigate = useNavigate()

    return (
        <>


            <TenantManagement />
            


        </>
    )
}

export default Dashboard;