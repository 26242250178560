import { useState } from "react";
import { TabMenu } from 'primereact/tabmenu';
import InputFormatTemplate from "./InputFormatTemplate";
import OutputFormatTemplate from "./OutputFormatTemplate";
import ShipTypeManagement from "./ShipTypeManagement/ShipTypeManagement";



const ManageTemplate = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [

        {
            label: "InputFormat Management",
            icon: "mdi mdi-cash-multiple",
            // url: "/PriceManagement",
        },
        {
            label: "OutputFormat Management",
            icon: "mdi mdi-account-group",
            // url: "/add-user-admin",
        },
        {
            label: "Shiptype Management",
            icon: "mdi mdi-account-group",
            // url: "/add-user-admin",
        },

    ];

    return (
        <>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                {activeIndex === 0 ?
                    <InputFormatTemplate /> :
                    activeIndex === 1 ? <OutputFormatTemplate /> : <ShipTypeManagement />

                }


            </div>


        </>
    );
};
export default ManageTemplate;
