import { useState, Fragment, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { ModelHoliday, ModelLevel, ModelMailEscalation } from "../../api";
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Calendar } from 'primereact/calendar';
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";

const EscalationMangement = () => {
    const properties = {
        title: "Escalation Management",
    };
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const setPageWaiting = usePageWaiting();
    const apiInstance = useSecureApiConnector()

    const [filter, setFilter] = useState("");

    const [holiday, setHoliday] = useState([])
    const [level, setLevel] = useState([])
    const [mailEscalation, setMailEscalation] = useState([])
    const [editEmail, setEditEmail] = useState(false);
    const [editEmailData, setEditEmailData] = useState({} as ModelMailEscalation);
    const [emailData1, setEmailData1] = useState("");
    const [emailData2, setEmailData2] = useState("");
    const [editHoliday, setEditHoliday] = useState(false);
    const [holidayData, setHolidayData] = useState({} as ModelHoliday);
    const [editHolidayData, setEditHolidayData] = useState("");
    const [dates1, setDates1] = useState<Date[]>([
    ]);
    const [editTimeFlag, setEditTimeFlag] = useState(false);
    const [editTimeL1, setEditTime1] = useState(0);
    const [editTimeL2, setEditTime2] = useState(0);
    const [editLevelData, setEditLevelData] = useState({} as ModelLevel);
    const [successToast, errorToast, ,] = useToast();

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const fetchData = () => {
        setPageWaiting(true);
        getLevels()
        getEmails()
        getHolidays()

    }

    const onShowEmail = () => {
        setPageWaiting(true);
        setEmailData1(editEmailData.mail_l1!)
        setEmailData2(editEmailData.mail_l2!)
        setPageWaiting(false);
    }

    const onShowHoliday = () => {

        if (editHolidayData !== null && editHolidayData !== "" && editHolidayData !== undefined) {
            let days = editHolidayData.split(",")
            if (days.length > 0) {
                let listDate: Date[] = []
                days.forEach(d => {
                    const parts = d.split('/'); // Split the string into an array using '/'
                    const start = new Date(`${parts[2]}-${parts[0]}-${parts[1]}`);
                    // let start = moment(d).toDate();
                    listDate.push(start)
                })
                setDates1(listDate)
            }
        }


    }

    const getLevels = async () => {
        await apiInstance.level.getAll().then(res => res.data)
            .then((data) => {
                setLevel(data as any)
            })
    }

    const getEmails = async () => {
        await apiInstance.escalationEmail.getAll().then(res => res.data)
            .then((data) => {
                setMailEscalation(data as any)

            })
    }

    const getHolidays = async () => {
        await apiInstance.holiday.getAll().then(res => res.data)
            .then((data) => {
                groupLevel(data)
            })
        setPageWaiting(false);

    }

    const groupLevel = (data: any) => {
        setHoliday(data as any)

    }

    const resetMail = () => {
        setEditEmail(false);
        setEditEmailData({});
        setEmailData1("");
        setEmailData2("");
    }

    const resetHoliday = () => {
        setEditHoliday(false);
        setEditHolidayData("");
        setDates1([]);
    }

    const renderFooter = (name: string) => {
        return (
            <>
                {name === "email" ? (
                    <div>
                        <Button label="No" icon="pi pi-times" onClick={() => { setEditEmail(false); resetMail() }} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={() => { confirmEscalationEmail() }} autoFocus />
                    </div>
                ) : (
                    <>
                        {name === "level" ? (
                            <div>
                                <Button label="No1" icon="pi pi-times" onClick={() => setEditEmail(false)} className="p-button-text" />
                                <Button label="Yes1" icon="pi pi-check" onClick={() => setEditEmail(false)} autoFocus />
                            </div>
                        ) : (
                            <>
                                {name === "holiday" ? (
                                    <div>
                                        <Button label="No" icon="pi pi-times" onClick={() => { resetHoliday() }} className="p-button-text" />
                                        <Button label="Yes" icon="pi pi-check" onClick={() => { selectDays() }} autoFocus />
                                    </div>
                                ) : (<></>)}
                            </>)}
                    </>)}




            </>

        );
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch]);



    const refresh = () => {
        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    useEffect(() => {

        // if (filter === " " && siteProperty !== initData) {
        //     setSiteProperty(initData);
        //     setTotalRecords(initData.length)
        // } else {
        //     setLoading(true);
        //     let sfilter = filter;
        //     if (moment(filter, "MMMM Do YYYY", true).isValid()) {
        //         sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        //     }

        //     apiInstance.webProperty
        //         .getSearchWebproperty({
        //             search: sfilter,
        //             offset: lazyParams.first,
        //             limit: lazyParams.rows,
        //         })
        //         .then((res) => res.data)
        //         .then((data) => {
        //             setTotalRecords(data.record_count!);
        //             setSiteProperty(data.record_list);
        //             setLoading(false);
        //         })
        //         .catch(() => {
        //             setSiteProperty([]);
        //             setLoading(false);
        //         });
        // }
    }, [filter]);

    const selectDays = () => {
        setEditTimeFlag(false)
        setPageWaiting(true);

        let days = ""

        dates1.forEach((d, i) => {
            console.log();
            if (i === 0) {
                days = days + moment(d).format("MM/DD/yyyy")

            } else {
                days = days + "," + moment(d).format("MM/DD/yyyy")
            }
        })

        apiInstance.holiday.update(holidayData.id!, days).then(res => res.data).then((data) => {
            getHolidays()
            setPageWaiting(false);

        }).catch((err) => {
            errorToast("Update fails. Please try again.")
            console.log(err);
            setPageWaiting(false);
        })

        resetHoliday();

    }

    const confirmUpdate = () => {
        setEditTimeFlag(false)
        setPageWaiting(true);
        apiInstance.level.update(editLevelData.id!, editTimeL1, editTimeL2).then((res) => res.data).then((data) => {
            getLevels()
            setPageWaiting(false);
        }).catch((err) => {
            errorToast("Update fails. Please try again.")
            console.log(err);
            setPageWaiting(false);
        })


    }

    const confirmEscalationEmail = () => {
        setEditEmail(false)
        setPageWaiting(true);
        apiInstance.escalationEmail.update(editEmailData.id!, emailData1, emailData2).then((res) => res.data).then((data) => {
            getEmails()
            setPageWaiting(false);
        }).catch((err) => {
            errorToast("Update fails. Please try again.")
            console.log(err);
            setPageWaiting(false);
        })
        resetMail()

    }

    return (
        <>
            <div className="grid grid-nogutter">
                <div className="col">

                    <Card >

                        <div className="flex" style={{ marginLeft: "2rem" }}>
                            <div>
                                <div className="mt-2 mb-4 font-bold text-v1">
                                    Level Config
                                </div>

                                <DataTable
                                    className="text-v2"
                                    value={level}
                                    lazy
                                    loading={loading}
                                    responsiveLayout="scroll"
                                    rowGroupMode="rowspan"
                                    groupRowsBy="status_name"
                                    sortMode="single"
                                    sortField="status_name"
                                    sortOrder={1}
                                    onPage={(e: any) => {
                                        setLazyParams(e);
                                    }}
                                    style={{ width: "50rem" }}
                                >
                                    <Column
                                        header="Status Name"
                                        field="status_name"
                                        style={{
                                            width: "5%",
                                            height: "55px",
                                        }}
                                    ></Column>
                                    <Column
                                        style={{
                                            width: "9%",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                            height: "55px",
                                        }}
                                        header="Level"
                                        field="name"

                                    ></Column>
                                    <Column
                                        style={{
                                            width: "20%",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                            height: "55px",
                                        }}
                                        header="Time L1(mins)"
                                        field="time_l1"
                                        body={(item: ModelLevel) => (
                                            <>
                                                {editTimeFlag && item.id === editLevelData.id ? (
                                                    <>
                                                        <InputText style={{ width: '7rem' }} value={editTimeL1} onChange={(e) => { setEditTime1(parseInt(e.target.value)) }} />

                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text-v2 ">
                                                            {item.time_l1}
                                                        </div>
                                                    </>
                                                )}


                                            </>
                                        )}
                                    ></Column>
                                    <Column
                                        style={{
                                            width: "20%",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                            height: "55px",
                                        }}
                                        header="Time L2(mins)"
                                        field="time_l2"
                                        body={(item: ModelLevel) => (
                                            <>
                                                {editTimeFlag && item.id === editLevelData.id ? (
                                                    <>
                                                        <InputText style={{ width: '7rem' }} value={editTimeL2} onChange={(e) => { setEditTime2(parseInt(e.target.value)) }} />

                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="text-v2">
                                                            {item.time_l2}
                                                        </div>

                                                    </>
                                                )}


                                            </>
                                        )}
                                    ></Column>

                                    <Column
                                        style={{
                                            // minWidth: "150px",
                                            width: "17%",
                                            height: "55px",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                        header=""
                                        body={(item: ModelLevel) => (
                                            <>
                                                {editTimeFlag && item.id === editLevelData.id ? (
                                                    <>
                                                        <div className="flex">
                                                            <Button icon="pi pi-check" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400"
                                                                style={{ borderRadius: "24px" }} onClick={() => { confirmUpdate() }} />
                                                            <Button icon="pi pi-times" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-red-400"
                                                                style={{ borderRadius: "24px" }} onClick={() => { setEditTimeFlag(false) }} />
                                                        </div>

                                                    </>
                                                ) : (<>
                                                    <Button icon="pi pi-pencil" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400"
                                                        style={{ borderRadius: "24px" }} onClick={() => { setEditLevelData(item); setEditTime1(item.time_l1!); setEditTime2(item.time_l2!); setEditTimeFlag(true) }} />
                                                </>)}


                                            </>
                                        )}

                                    ></Column>

                                </DataTable>


                            </div>
                            <div style={{ marginLeft: "4rem" }}>
                                <div className=" mt-2 mb-4 font-bold text-v1">
                                    Holidays Config
                                </div>
                                <DataTable
                                    className="text-v2"
                                    value={holiday}
                                    lazy
                                    loading={loading}
                                    responsiveLayout="scroll"
                                    style={{ width: "15rem" }}
                                >
                                    <Column
                                        header="Month"
                                        field="month"
                                        style={{
                                            // minWidth: "300px",
                                            width: "10%",
                                            height: "55px",
                                        }}
                                    ></Column>
                                    <Column
                                        style={{
                                            // minWidth: "150px",
                                            width: "10%",
                                            height: "55px",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                        header=""
                                        body={(item: ModelHoliday) => (
                                            <>
                                                <Button icon="mdi mdi-calendar"
                                                    className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400"
                                                    style={{ borderRadius: "24px" }} onClick={() => { setHolidayData(item); setEditHolidayData(item.days!); setEditHoliday(true) }} />

                                            </>
                                        )}

                                    ></Column>

                                </DataTable>
                            </div>
                            <div style={{ marginLeft: "4rem" }}>
                                <div className="mt-2 mb-4 font-bold text-v1 email-table-container">
                                    Email Config
                                </div>
                                <DataTable
                                    className="text-v2"
                                    value={mailEscalation}
                                    lazy
                                    loading={loading}
                                    responsiveLayout="scroll"
                                    // paginator
                                    // first={lazyParams.first}
                                    // rows={lazyParams.rows}
                                    // totalRecords={totalRecords}
                                    onPage={(e: any) => {
                                        setLazyParams(e);
                                    }}
                                    style={{ width: "40rem" }}
                                // paginatorTemplate={paginatorTemplate}
                                // paginatorClassName="justify-content-end"
                                >
                                    <Column
                                        style={{
                                            width: "40%",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                            height: "55px",
                                        }}
                                        header="Email L1"
                                        field="mail_l1"

                                    ></Column>
                                    <Column
                                        style={{
                                            width: "40%",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                            height: "55px",
                                        }}
                                        header="Email L2"
                                        field="mail_l2"
                                    ></Column>

                                    <Column
                                        style={{
                                            // minWidth: "150px",
                                            width: "10%",
                                            height: "55px",
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-all",
                                        }}
                                        header=""
                                        body={(item: ModelMailEscalation) => (
                                            <>
                                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400"
                                                    style={{ borderRadius: "24px" }} onClick={() => { setEditEmailData(item); setEditEmail(true) }} />


                                            </>
                                        )}

                                    ></Column>

                                </DataTable>
                            </div>

                        </div>

                    </Card>
                </div>
            </div>
            <Dialog header="Edit Email" onShow={onShowEmail} visible={editEmail} onHide={() => { resetMail() }} breakpoints={{ '960px': '75vw' }} style={{ width: '50vw' }} footer={renderFooter('email')}>
                <>
                    <div className="mt-5 p-float-label">
                        <InputTextarea style={{ marginLeft: "2rem" }} id="email_l1" value={emailData1} onChange={(e) => setEmailData1(e.target.value)} rows={5} cols={50} autoResize />
                        <label style={{ marginLeft: "1rem" }} htmlFor="email_l1">Email Level1</label>

                        <InputTextarea style={{ marginLeft: "4rem" }} id="email_l2" value={emailData2} onChange={(e) => setEmailData2(e.target.value)} rows={5} cols={50} autoResize />
                        <label style={{ marginLeft: "29.5rem" }} htmlFor="email_l2">Email Level2</label>
                    </div>
                </>
            </Dialog>

            <Dialog header="Select Days" onShow={onShowHoliday} visible={editHoliday} onHide={() => { resetHoliday() }} breakpoints={{ '960px': '75vw' }} style={{ width: '30rem' }} footer={renderFooter('holiday')}>
                <>
                    <div className="mt-3">
                        <Calendar style={{ marginLeft: '1rem', width: '25.5rem' }} id="multiple" value={dates1} onChange={(e) => { setDates1(e.value as Date[]) }} selectionMode="multiple" readOnlyInput disabled />

                    </div>
                    <div className="mt-3">
                        <Calendar style={{ marginLeft: '1rem' }} id="multiple" value={dates1} onChange={(e) => { setDates1(e.value as Date[]) }} selectionMode="multiple" inline readOnlyInput />

                    </div>
                </>
            </Dialog>
        </>
    )
}
export default EscalationMangement;
