import { useCheckAdmin } from "../../utils/Properties";
import { useState, Fragment, useEffect, useRef } from "react";
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { ModelsSupport } from "../../api";
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip"
import SearchDialog from "../../components/SearchDialog";
import SupportProgess from "../../components/Dialog/SupportProgess";

const Support = () => {
    const properties = {
        title: "Support Management",
    };
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);

    const [mogData, setMogData] = useState([] as ModelsSupport[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const apiInstance = useSecureApiConnector()
    const [visible, setVisible] = useState<boolean>(false);
    const [visible2, setVisible2] = useState<boolean>(false);
    const [successToast, errorToast, ,] = useToast();
    const [support, setSupport, ,] = useState({} as ModelsSupport);
    const [support2, setSupport2, ,] = useState({} as ModelsSupport);
    const [selectedCity2, setSelectedCity2] = useState<any>(null);
    const [visible1, setVisible1] = useState<boolean>(false);
    const [noCharge, setNoCharge] = useState(false)
    const [noChargeUploadId, setNoChargeUploadId] = useState("");
    const [filter, setFilter] = useState("");
    const [initData, setInitData] = useState([] as ModelsSupport[]);
    const [dialogSearch, setSearchDialog] = useState(false);
    const [supportProgessDialog, setSupportProgessDialog] = useState(false);
    const [supportProgessData, setSupportProgessData] = useState({} as ModelsSupport)

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const fetchData = () => {
        apiInstance.support.getOffset(lazyParams.first, lazyParams.rows).then(res => res.data)
            .then(data => {
                setTotalRecords(data.record_count!)
                setMogData(data.record_list)
                setInitData(data.record_list)
                setLoading(false)
            })
    }
    const submit = () => {
        apiInstance.support.update(support.id!, "").then(res => res.data)
            .then(data => {
                refresh();
            })
    }

    const submitClearTransactionByUploadId = () => {
        apiInstance.facilities.clearFreightTransactionByUploadID(noChargeUploadId)
        setNoCharge(false)
        successToast("Clear transaction succese")
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch]);

    useEffect(() => {
        setLoading(true);
        let sfilter = filter;
        if (moment(filter, "MMMM Do YYYY", true).isValid()) {
            sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        }

        apiInstance.support
            .getSearchTenant({
                search: sfilter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setMogData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setMogData([]);
                setLoading(false);
            });
    }, [lazyParams]);

    const refresh = () => {
        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const reject = () => {
        successToast("test")
        selectedCity2("")
    }

    const cities = [
        { name: 'Critical', level: 0 },
        { name: 'High', level: 1 },
        { name: 'Medium', level: 2 },
        { name: 'Low', level: 3 },
    ];

    const cities2 = [
        { name: 'High', level: 0 },
        { name: 'Medium', level: 1 },
        { name: 'Low', level: 2 },
    ];

    const onPlatformChange = (e: { value: any }) => {
        setVisible2(true)
        setSelectedCity2(e.value);
    }

    const submit2 = () => {

        apiInstance.support.update(support2.id!, selectedCity2.level).then(res => res.data)
            .then(data => {
                refresh();
            })
        setVisible2(false)
        setVisible1(false)
        setSelectedCity2("");

    }

    useEffect(() => {

        if (filter === " " && mogData !== initData) {
            setMogData(initData);
            setTotalRecords(initData.length)
        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.support
                .getSearchTenant({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setMogData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setMogData([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    const submitStag = () => {

    }

    return (
        <>
            <SearchDialog
                visible={dialogSearch}
                setVisible={setSearchDialog}
                filter={filter}
                setFilter={setFilter}
            />
            <div className="grid grid-nogutter">
                <div className="col">
                    <ConfirmDialog visible={visible} onHide={() => setVisible(false)} message="Are you sure you want to close issue?"
                        header="Confirmation" icon="pi pi-exclamation-triangle" accept={submit} reject={reject} />
                    <ConfirmDialog visible={visible2} onHide={() => setVisible2(false)} message="Are you sure you want to change level?"
                        header="Confirmation" icon="pi pi-exclamation-triangle" accept={submit2} reject={reject} />
                    <ConfirmDialog visible={noCharge} onHide={() => setNoCharge(false)} message="Are you sure you want to clear transaction?"
                        header="Confirmation" icon="pi pi-exclamation-triangle" accept={submitClearTransactionByUploadId} reject={reject} />
                    <SupportProgess visible={supportProgessDialog} setVisible={setSupportProgessDialog} data={supportProgessData}
                        setData={setSupportProgessData} onSubmit={submitStag} refresh={refresh} />

                    <Card >
                        <div className="mt-2 mb-4 font-bold text-v1">
                            {properties.title}
                        </div>

                        <div className="w-full relative flex" style={{ height: "48px", marginBottom: "1rem" }}>
                            <span
                                className="absolute hidden md:flex p-input-icon-right"
                                style={{ right: "1rem", top: "5px" }}
                            >
                                <InputText
                                    className="w-25rem custom-search-box text-v3"
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e.target.value);
                                    }}
                                    placeholder="  Keyword Search..."
                                />
                                <i className="pi pi-search gable-text-dark-blue" />
                            </span>
                            <Button
                                icon="mdi mdi-magnify"
                                style={{ right: "1rem", top: "5px" }}
                                className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                onClick={() => {
                                    //   setSearchDialog(true);
                                }}
                            />
                        </div>

                        <DataTable
                            className="text-v2"
                            value={mogData}
                            lazy
                            loading={loading}
                            responsiveLayout="scroll"
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={totalRecords}
                            onPage={(e: any) => {
                                setLazyParams(e);
                            }}
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                        >
                            <Column
                                header="Timestamp"
                                field="created_at"
                                style={{
                                    // minWidth: "300px",
                                    width: "10%",
                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>{moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                                )}
                            ></Column>
                            <Column
                                header="ID"
                                field="id"
                                style={{
                                    // minWidth: "300px",
                                    width: "20%",
                                    height: "55px",
                                }}
                            ></Column>
                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "5%",

                                    height: "55px",
                                }}
                                header="Type"
                                field="type"

                            ></Column>
                            <Column
                                style={{
                                    width: "15%",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                    height: "55px",
                                }}
                                header="Topic"
                                field="topic"
                            ></Column>

                            <Column
                                style={{
                                    width: "10%",
                                    height: "55px",
                                }}
                                header="Level"
                                field="level"
                                body={(item: ModelsSupport) => (
                                    <>
                                        {visible1 && item.id === support2.id! ? (
                                            <>
                                                {item.type! === 'Platform' ? (
                                                    <Dropdown value={selectedCity2} options={cities} onChange={onPlatformChange} optionLabel="name" />
                                                ) : (
                                                    <Dropdown value={selectedCity2} options={cities2} onChange={onPlatformChange} optionLabel="name" />
                                                )}
                                            </>
                                        ) : (
                                            item.type! === 'Platform' ? (item.level === 0 ? "Critical" : item.level === 1 ? "High" : item.level === 2 ? "Medium" : "Low") : (item.level === 0 ? "High" : item.level === 1 ? "Medium" : "Low")
                                        )}
                                    </>
                                )}
                            ></Column>

                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "12%",
                                    height: "55px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                }}
                                header="Summary"
                                field="summary"


                            ></Column>
                            <Column
                                alignHeader={"center"}
                                header="Status"
                                field="status"
                                style={{
                                    // minWidth: "150px",
                                    width: "8%",

                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>
                                        {item.status === "Open" && (
                                            <div
                                                className="status-pending margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-question-circle"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Open</span>
                                            </div>
                                        )}

                                        {item.status === "Acknowledge" && (
                                            <div
                                                className="status-acknowledge margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-eye"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Acknowledge</span>
                                            </div>
                                        )}

                                        {item.status === "Resolving" && (
                                            <div
                                                className="status-resolving margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-search"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Resolving</span>
                                            </div>
                                        )}

                                        {item.status === "Resolved" && (
                                            <div
                                                className="status-resolved margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-check"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Resolved</span>
                                            </div>
                                        )}

                                        {item.status === "Closed" && (
                                            <div
                                                className="status-success margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-star"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Closed</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            />

                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "20%",
                                    height: "55px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                }}
                                header=""
                                body={(item: ModelsSupport) => (
                                    <>
                                        <Button className='cursor-pointer  p-button-outlined mr-2' label="Progress" onClick={() => {
                                            setSupportProgessDialog(true); setSupportProgessData(item)
                                        }} />
                                        <Button icon="pi pi-dollar" className='cursor-pointer tooltip-target p-button-outlined' data-pr-tooltip="No Charge" onClick={() => {
                                            setNoCharge(true); setNoChargeUploadId(item.upload_id!);
                                        }} />

                                        {item.status === "Open" ? (
                                            <>
                                                {/* <Button icon="pi pi-check" className="p-button-rounded p-button-text" style={{ borderRadius: "24px" }} onClick={() => { setSupport(item); setVisible(true) }} /> */}
                                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text" style={{ borderRadius: "24px" }} onClick={() => { setSupport2(item); setVisible1(true) }} />
                                                <Tooltip target=".tooltip-target" position="left" />


                                            </>
                                        ) : (<></>)}

                                        {visible1 && item.id === support2.id! ? (
                                            <Button icon="pi pi-times" className="p-button-rounded p-button-text" style={{ borderRadius: "24px" }} onClick={() => { setVisible1(false); setSelectedCity2("") }} />

                                        ) : (<></>)}

                                    </>
                                )}

                            ></Column>

                        </DataTable>
                    </Card>
                </div>
            </div>
        </>




    )
}
export default Support;
