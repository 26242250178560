import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsShipType } from "../../api"
import { usePageWaiting } from "../../utils/Properties"
import "./style.css"

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    data: ModelsShipType
    setData: Dispatch<SetStateAction<ModelsShipType>>
    onSubmit: (data: any) => void;
    edit?: boolean
    editTiltle?: string
}

const CreateShipType: FC<props> = ({ data, visible, edit, editTiltle, setVisible, refresh, onSubmit }) => {
    const title = "Create Shiptype"
    const setPageWating = usePageWaiting()
    const defaultValues = {
        name: '',
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });

    const setData = async () => {
        setPageWating(true)

        setValue('name', data.name!);


        setPageWating(false)
    }

    useEffect(() => {
        reset()
        if (edit) {
            setData()
        }
    }, [visible])


    return (
        <>
            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog ship-type-dialog p-0 m-0 w-23rem "
                showHeader={false}
                style={{ minWidth: '30rem' }}
                onHide={() => {
                    setVisible(false)
                    reset()

                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{ marginTop: '2rem' }}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {editTiltle ? editTiltle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            {editTiltle ? editTiltle.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    reset()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>

                <div className="py-2 px-4 w-full text-v2 font-bold">

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                        <div className="flex">
                            <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">Name<span style={{ color: 'red' }}>*</span></p>
                                <Controller name="name" control={control} rules={{
                                    required: 'Name is required.',
                                }} render={({ field }) => (
                                    <InputText id={field.name} {...field} autoFocus
                                        className={`${!!errors.name ? 'p-invalid' : ''} input-border`}
                                    />
                                )} /></div>


                        </div>

                        <div>
                            <Button
                                label="Submit"
                                type="submit"
                                className="p-button mt-3 transition-duration-300 hover:bg-indigo-700 add-template"
                                style={{ borderRadius: '30px', width: '194px', background: '#292666', marginBottom: '2rem' }}
                            />
                        </div>

                        {((errors.name)) &&

                            <div className="pl-2 mt-2">
                                <p className="p-error font-bold m-0">Hint</p>
                                <p className="m-0 p-error">{errors.name ? <small>{errors.name.message}</small> : <></>}</p>
                            </div>
                        }


                    </form>
                </div>
            </Dialog>


        </>
    )
}

export default CreateShipType;