import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory, ModelsInputFormat, ModelsOutputFormat } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Dropdown } from "primereact/dropdown"
import "./style.css"
import { useClipboard } from "../../utils/CopyToClipboard"
import { Tooltip } from "primereact/tooltip"
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    data: any
    setData: Dispatch<SetStateAction<any>>
    onSubmit: (data: any) => void;
    titleName?: string;
    fieldName?: string;
    tabName?: string;
    tenant_id?: string;
}

const AddRbacInputDialog: FC<props> = ({ data, visible, setData, setVisible, refresh, onSubmit, titleName, fieldName, tabName, tenant_id }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Add RBac InputFormat Template"
    const setPageWating = usePageWaiting()
    const [selectedCities1, setSelectedCities1] = useState([]);
    const [inputTemp, setinputTemp] = useState([] as ModelsInputFormat[]);
    const [inputTemp2, setinputTemp2] = useState([] as ModelsOutputFormat[]);
    const [viewFormat, setViewFormat] = useState([] as any);

    const defaultValues = {
        modelsInputFormat: [] as ModelsInputFormat[],
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });

    useEffect(() => {
        if (visible) {
            setinputTemp([])
            setinputTemp2([])
            setSelectedCities1([])
            if (tabName === "output format") {
                apiInstance.outputFormat.getAllWithoutRbac(tenant_id!)
                    .then(res => res.data)
                    .then((data) => {
                        setinputTemp2(data.record_list)
                    })               

            } else {
                apiInstance.inputFormat.getAllWithoutRbac(tenant_id!)
                    .then(res => res.data)
                    .then((data) => {
                        setinputTemp(data.record_list)
                    })
                
            }

        }
    }, [visible])


    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-23rem "
                showHeader={false}
                style={{ minWidth: '40rem' }}
                onHide={() => {
                    setVisible(false)
                    reset()
                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{ marginTop: '2rem' }}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            {titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setinputTemp([])
                                    setSelectedCities1([])
                                    setVisible(false)
                                    reset()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="py-2 px-4 w-full text-v2 font-bold">

                    <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                        <p className="mb-2 mt-2 font-bold text-sm">{fieldName}<span style={{ color: 'red' }}>*</span></p>
                        <div className="flex">
                            {tabName === "output format" ?
                                <MultiSelect className={`${selectedCities1.length <= 0 ? 'p-invalid' : ''} `}
                                    value={selectedCities1} options={inputTemp2}
                                    onChange={(e) => setSelectedCities1(e.value)}
                                    optionLabel="name"
                                    placeholder="Select format" maxSelectedLabels={3}
                                    style={{ width: '19rem', height: '3rem', borderRadius: '2rem' }}
                                /> :
                                <MultiSelect className={`${selectedCities1.length <= 0 ? 'p-invalid' : ''} `}
                                    value={selectedCities1} options={inputTemp}
                                    onChange={(e) => setSelectedCities1(e.value)}
                                    optionLabel="name"
                                    placeholder="Select format" maxSelectedLabels={3}
                                    style={{ width: '19rem', height: '3rem', borderRadius: '2rem' }}
                                />
                            }
                            <Button
                                label="Submit"
                                type="submit"
                                className="p-button ml-8 transition-duration-300 hover:bg-indigo-700"
                                style={{ borderRadius: '30px', width: '194px', background: '#292666', marginBottom: '2rem' }}
                                onClick={() => {
                                    setData(selectedCities1)
                                }}
                            />
                        </div>

                        {(selectedCities1.length <= 0) &&
                            <div className="pl-2 mt-2">
                                <p className="p-error font-bold m-0">Hint</p>
                                <p className="m-0 p-error">{<small>{"Can not be empty."}</small>}</p>
                            </div>
                        }


                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default AddRbacInputDialog;