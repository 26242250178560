import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import "./style.css"

interface props {
    filter: string
    setFilter: Dispatch<SetStateAction<string>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
}

const AddDirectoryDialog: FC<props> = ({ visible, setVisible, filter, setFilter }) => {
    const title = "Search"
    const [search, setSearch] = useState('');

    const onSubmit = (e: any) => {
        e.preventDefault()
        setFilter(search)
        setVisible(false)
    };

    useEffect(() => {
        if (search === "" && search !== filter) {
            setSearch(filter)
        }
    }, [filter])

    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setSearch('')
            }}
        >
            <div className="w-full gable-bg-gray py-2 px-4">
                <div className="grid grid-nogutter">
                    <div className="col-10 text-xl hidden md:flex text-white relative">
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-2 text-right">
                        <Badge value="X"
                            className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                            severity="danger"
                            onClick={() => {
                                setVisible(false)
                                setSearch('')
                            }}
                        ></Badge>
                    </div>
                </div>
            </div>
            <div className="p-3">

                <form onSubmit={onSubmit} className="p-fluid">

                    <p className="mb-2 mt-0 font-bold text-sm">Keyword</p>

                    <InputText
                        autoFocus
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />

                    <Button
                        label="Submit"
                        type="submit"
                        className="p-button p-button-secondary mt-3"
                    />
                </form>
            </div>
        </Dialog>
    )
}

export default AddDirectoryDialog;