import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { CustomData } from "../api";
import "./style.css";
import { Card } from "primereact/card";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { start } from "repl";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import TotalDetailDialog from "../components/Dialog/TotalDetailDialog";
import { useFileWithType } from "../utils/File";
import { useToast } from "../utils/Properties";

function PriceManagement() {
  const apiInstance = useSecureApiConnector();
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [data, setData] = useState([] as CustomData[]);
  const [expandedRows, setExpandedRows] = useState(null as any);
  const [expandedUserRows, setExpandedUserRows] = useState(null as any);
  const allowExpansion = (rowData: any) => {
    return !!rowData.total_directories;
  };
  const allowUsersExpansion = (rowData: any) => {

    return !!rowData.total_users;
  };


  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [dialogUserTotalPagesDetail, setDialogUserTotalPagesDetail] = useState(false);
  const [dialogDirectoryTotalPagesDetail, setDialogDirectoryTotalPagesDetail] = useState(false);
  const [dialogTenantTotalPagesDetail, setDialogTenantTotalPagesDetail] = useState(false);
  const [tenantId, setTenantId] = useState("")
  const [directoryId, setDidectoryId] = useState("")
  const [userId, setUserId] = useState("")
  const saveAsWithType = useFileWithType();
  const [successToast, errorToast, , warnToast] = useToast();

  const [dateRange, setDateRange] = useState<Date[]>([
    moment().clone().startOf("month").toDate(),
    new Date(),
  ]);

  const fetchData = () => {
    apiInstance.tenants
      .getDataCalculate(
        moment(dateRange[0]).format("yyyy-MM-DD"),
        moment(dateRange[1]).format("yyyy-MM-DD")
      )
      .then((res) => res.data)
      .then((data) => {
        setLoading(false);
        setData(data.record_list);
        setTriggerFetch(false);
      });
  };

  const refresh = () => {
    setTriggerFetch(triggerFetch => !triggerFetch);
  }

  useEffect(() => {
    if (!!dateRange[0] && !!dateRange[1]) {
      setLoading(true);
      fetchData();
    }
  }, [dateRange]);

  const rowExpansionUsersTemplate = (item: any) => {
    return (
      <>
        <TotalDetailDialog
          visible={dialogUserTotalPagesDetail}
          setVisible={setDialogUserTotalPagesDetail}
          setTenantId={setTenantId}
          setDirectoryId={setDidectoryId}
          setUserId={setUserId}
          title_name="Total Pages Detail"
          start_date={moment(dateRange[0]).format("yyyy-MM-DD")}
          end_date={moment(dateRange[1]).format("yyyy-MM-DD")}
          tenant_id={tenantId}
          directory_id={directoryId}
          user_id={userId}
          refresh={refresh}
        />
        <div className="orders-subtable">
          <DataTable
            value={item.total_users}
            size="small"
            responsiveLayout="scroll"
            sortField="total_page_count"
            sortOrder={-1}
          >
            <Column field="none" style={{ width: "10%" }}></Column>
            <Column
              field="name"
              header="Uploaded By"
              style={{ width: "70%" }}
            ></Column>
            <Column
              field="total_page_count"
              header="Total Page Count"
              sortable
              style={{ width: "15%" }}
            ></Column>
            <Column
              style={{ width: "20%" }}
              body={(item: any) => (
                <div>
                  <Button
                    label="view"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      // marginTop: "2rem",
                      // marginLeft: "14rem"
                    }}
                    onClick={() => {
                      setTenantId("")
                      setDidectoryId("")
                      setUserId(item.user_id)
                      setDialogUserTotalPagesDetail(true)

                    }}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };

  const rowExpansionTemplate = (item: any) => {
    return (
      <>
        <TotalDetailDialog
          visible={dialogDirectoryTotalPagesDetail}
          setVisible={setDialogDirectoryTotalPagesDetail}
          setTenantId={setTenantId}
          setDirectoryId={setDidectoryId}
          setUserId={setUserId}
          title_name="Total Pages Detail"
          start_date={moment(dateRange[0]).format("yyyy-MM-DD")}
          end_date={moment(dateRange[1]).format("yyyy-MM-DD")}
          tenant_id={tenantId}
          directory_id={directoryId}
          user_id={userId}
          refresh={refresh}
        />
        <div className="orders-subtable">
          <DataTable
            value={item.total_directories}
            size="small"
            responsiveLayout="scroll"
            expandedRows={expandedUserRows}
            onRowToggle={(e) => {
              setExpandedUserRows(e.data);
            }}
            sortField="total_page_count"
            sortOrder={-1}
            rowExpansionTemplate={rowExpansionUsersTemplate}
          >
            <Column field="none" style={{ width: "2%" }}></Column>
            <Column expander={allowUsersExpansion} />
            <Column
              field="name"
              header="Branch Name"
              style={{ width: "72%" }}
            ></Column>
            <Column
              field="total_page_count"
              header="Total Page Count"
              sortable
              style={{ width: "20%" }}
            ></Column>
            <Column
              style={{ width: "20%" }}
              body={(item: any) => (
                <div>
                  <Button
                    label="view"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      // marginTop: "2rem",
                      // marginLeft: "14rem"
                    }}
                    onClick={() => {
                      // navigate("/user-management");
                      // console.log(item);
                      setTenantId("")
                      setUserId("")
                      setDidectoryId(item.directory_id)
                      setDialogDirectoryTotalPagesDetail(true)

                    }}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </div>
      </>
    );
  };

  const downloadFilePDF = (tenant_id: string) => {    
    setLoading(true);
    apiInstance.tenants
      .generatePDF(tenant_id, moment(dateRange[0]).format("yyyy-MM-DD"),
        moment(dateRange[1]).format("yyyy-MM-DD"))
      .then((res) => {
        saveAsWithType(
          `Billing Cycle ${moment(dateRange[1]).format("MMM-YY")}.zip`,
          res.data,
          "application/zip"
        );
      })
      .catch(() => {
        errorToast("download file failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <TotalDetailDialog
        visible={dialogTenantTotalPagesDetail}
        setVisible={setDialogTenantTotalPagesDetail}
        setTenantId={setTenantId}
        setDirectoryId={setDidectoryId}
        setUserId={setUserId}
        title_name="Total Pages Detail"
        start_date={moment(dateRange[0]).format("yyyy-MM-DD")}
        end_date={moment(dateRange[1]).format("yyyy-MM-DD")}
        tenant_id={tenantId}
        directory_id={directoryId}
        user_id={userId}
        refresh={refresh}
      />
      <div
        style={{
          display: "inline-block",
          justifyContent: "left",
          alignItems: "center",
          height: "30vh",
          width: "100%",
        }}
      >
        <Card className="card-pad" title="Price Management">
          <div className="mb-2">
            <label htmlFor="range">Date Filter</label>
            <Calendar
              id="range"
              style={{ width: "250px" }}
              className="ml-3"
              value={dateRange}
              onChange={(e) => setDateRange(e.value as Date[])}
              selectionMode="range"
              readOnlyInput
            />
          </div>
          <DataTable
            value={data}
            expandedRows={expandedRows}
            onRowToggle={(e) => {
              setExpandedRows(e.data);
            }}
            // lazy
            loading={loading}
            paginator
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            rows={5}
            rowsPerPageOptions={[5, 10, 25, 50]}
            tableStyle={{ minWidth: "100%" }}
            sortField="total_page_count"
            sortOrder={-1}
          >
            <Column expander={allowExpansion} />
            <Column
              field="tenant_name"
              header="Tenant Name"
              style={{ width: "35%" }}
            ></Column>
            <Column
              field="name"
              header="Name"
              style={{ width: "35%" }}
            ></Column>
            <Column
              field="total_page_count"
              header="Total Page Count"
              style={{ width: "20%" }}
              sortable
            ></Column>
            <Column
              style={{ width: "25%" }}
              body={(item: any) => (
                <div>
                  <Button
                    label="PDF"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      // marginTop: "2rem",
                      // marginLeft: "14rem"
                    }}
                    onClick={() => {
                      downloadFilePDF(item.tenant_id)
                    }}
                  />
                  <Button
                    label="view"
                    className="p-button  p-button-rounded transition-duration-200 cursor-pointer hover:bg-indigo-700"
                    style={{
                      background: "#292666",
                      height: "30px",
                      width: "80px",
                      // marginTop: "2rem",
                      // marginLeft: "14rem"
                    }}
                    onClick={() => {
                      // navigate("/user-management");
                      // console.log(item.tenant_id);
                      setTenantId(item.tenant_id)
                      setDidectoryId("")
                      setUserId("")
                      setDialogTenantTotalPagesDetail(true)

                    }}
                  />
                </div>
              )}
            ></Column>
          </DataTable>
        </Card>
      </div>
    </>
  );
}

export default PriceManagement;
