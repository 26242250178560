import { Stream } from "stream";

const getCookie = (cookieName: string) => {
  let cookie = {} as any;
  document.cookie.split(';').forEach(function (el) {
    let [key, value] = el.split('=');
    cookie[key.trim()] = value;
  })
  return cookie[cookieName];
}

type APIRequestParams<T> = {
  directory_id?: string;
  tenant_id?: string;
  data?: T;
};


export type GinH = Record<string, any>;

export interface GormDeletedAt {
  time?: string;

  /** Valid is true if Time is not NULL */
  valid?: boolean;
}
export interface ModelsAssets {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
  value?: string;
}

export interface ModelsSupport {
  timestamp?: string;
  id?: string;
  topic?: string;
  type?: string;
  level?: number;
  summary?: string
  status?: string;
  updated_at?: string;
  created_at?: string;
  upload_id?: string;
}
export interface ModelTenant {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  updated_at?: string;
  name?: string;
  customer_name?: string;
  customer_address?: string;
  customer_telnumber?: string;
  customer_email?: string;
  directories?: ModelsDirectory[];
}



export interface ModelTenantInputFormat {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  tenant_id?: string;
  input_format_id?: string;
}

export interface ModelInputFormatShipType {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  ship_type_id?: string;
  ship_type?: ModelsShipType;
  input_format_id?: string;
  input_format?: ModelsInputFormat;
}

export interface ModelTenantOutputFormat {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  tenant_id?: string;
  output_format_id?: string;
}
export interface ModelLevel {
  id?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  status_name?: string;
  time_l1?: number;
  time_l2?: number;
}

export interface ModelHoliday {
  id?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  month?: string;
  days?: string;
}

export interface ModelMailEscalation {
  id?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  updated_at?: string;
  mail_l1?: string;
  mail_l2?: string;
}

export interface ModelsDirectory {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  processes?: ModelsProcess[];
  r_groups?: ModelsRGroup[];
  updated_at?: string;
  users?: ModelsUser[];
  tenant?: ModelTenant;
}

export interface ModelsIAMDirectory {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsIAMGlobal {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsIAMProcess {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  process?: ModelsProcess;
  process_id?: string;
  role?: ModelsRole;
  role_id?: string;
  updated_at?: string;
  user?: ModelsUser;
  user_id?: string;
}

export interface ModelsJobSchedule {
  at_time?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  deploy_mode?: string;
  enable?: boolean;
  every?: ModelsTimeTriggerEvery;
  every_id?: string;
  every_interval?: number;
  id?: string;
  mode?: ModelsTimeTriggerMode;
  mode_id?: string;
  on_date?: string;
  process?: ModelsProcessVersion;
  process_id?: string;
  rgroup?: ModelsRGroup;
  rgroup_id?: string;
  robots?: ModelsRobot[];
  updated_at?: string;
  uuid?: string;
}

export interface ModelsProcess {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  id?: string;
  name?: string;
  updated_at?: string;
  vault_hub?: ModelsVaultHub;
  vault_hub_id?: string;
  versions?: ModelsProcessVersion[];
}

export interface ModelsProcessAsset {
  asset?: ModelsAssets;
  asset_id?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  pv?: ModelsProcessVersion;
  pv_id?: string;
  updated_at?: string;
}

export interface ModelsProcessVersion {
  asset_variables?: ModelsProcessAsset[];
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  process?: ModelsProcess;
  process_id?: string;
  updated_at?: string;
  uuid?: string;
  version?: string;
}

export interface ModelsRGroup {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  directory_id?: string;
  id?: string;
  job_schedules?: ModelsJobSchedule[];
  name?: string;
  robots?: ModelsRobot[];
  updated_at?: string;
}

export interface ModelsRobot {
  allow_access?: boolean;
  secret?: string;
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  ingroup?: ModelsRGroup;
  ingroup_id?: string;
  ip_address?: string;
  job_schedules?: ModelsJobSchedule[];
  name?: string;
  updated_at?: string;
  directory_id?: number;
}

export interface ModelsRole {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  iam_directories?: ModelsIAMDirectory[];
  iam_processes?: ModelsIAMProcess[];
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface ModelsTimeTriggerEvery {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
  value?: number;
}

export interface ModelsTimeTriggerMode {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  updated_at?: string;
}

export interface ModelsUAuthentication {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  passwd_checksum?: string;
  updated_at?: string;
  user?: ModelsUser;
}

export interface ModelsUser {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  directory?: ModelsDirectory;
  role?: ModelsRole;
  directory_id?: string;
  email?: string;
  enable?: boolean;
  first_name?: string;
  id?: string;
  last_name?: string;
  tel_number?: string;
  personal_id?: string;
  need_chpasswd?: boolean;
  temporary_password?: string;
  uauth?: ModelsUAuthentication;
  uauth_id?: string;
  updated_at?: string;
}

export interface ModelsVaultHub {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  customer_secret?: string;
  name?: string;
  processes?: ModelsProcess[];
  retention_day?: number;
}

export interface TypesCredentialParams {
  email?: string;
  password?: string;
  directory_activated_id?: string;
}

export interface TypesDeploy2Robot {
  client_id?: string;
  event?: string;
  proc?: TypesDeployProcType;
}

export interface TypesDeployProcType {
  id?: string;
  mode?: string;
  p_name?: string;
  p_version?: string;
  pv_uuid?: string;
  type?: string;
}

export interface TypesForgetPasswordResponse {
  email?: string;
}

export interface TypesProcessQueue {
  id?: string;
  mode?: string;
  process?: ModelsProcessVersion;
  rg?: ModelsRGroup;
  robots?: ModelsRobot[];
  status?: string;
}

export interface TypesProcessSchedule {
  mode?: string;
  process?: ModelsProcessVersion;
  rg?: ModelsRGroup;
  robots?: ModelsRobot[];
  trig_at?: string;
  trig_every?: string;
  trig_every_interval?: number;
  trig_mode?: string;
  trig_on?: string;
}

export interface TypesRobotAllocationParams {
  g_id?: string;
  robots?: ModelsRobot[];
}

export interface TypesToggleSchedultStatus {
  id?: string;
}

export interface TypesTokenResponse {
  access_token?: string;
  access_token_expires?: string;
  need_chpasswd?: boolean;
  default_directory_id?: string;
  default_tenant_id?: string;
}

export interface TypesUserChangePasswdParams {
  new_password?: string;
  old_password?: string;
  u_id?: string;
}

export interface TypesUserForgetPasswdParams {
  email?: string;
}

export interface TypesUserResetPasswdParams {
  new_password?: string;
  old_password?: string;
  secret?: string;
  u_id?: string;
}

export interface UtilsArrayResponse {
  record_count?: number;
  record_list?: any;
}

export interface ModelsUploadFile {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  file_type?: string;
}

export interface ModelsResultCsvFile {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  invoice_no?: string;
  company?: string;
  file_type?: string;
}

export interface ModelsShipType {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface SupportProgess {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  detail?: string;
  stage?: string;
  support_id?: string;
  icon?: string;
  color?: string;
}

export interface ModelsUploadMode {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}

export interface ModelsFormat {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
}
export interface UserTotalPageDetail {
  month?: string;
  total?: number;
}

export interface ModelsInputFormat {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  enable?: boolean
}

export interface ModelsOutputFormat {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;
  name?: string;
  enable?: boolean
}

export interface ModelsUpload {
  created_at?: string;
  updated_at?: string;
  deleted_at?: GormDeletedAt;
  id?: string;

  name?: string;
  remark?: string;
  status?: string;
  page_count?: number;
  invoice_count?: number;
  files?: ModelsUploadFile[];
  csv_files?: ModelsResultCsvFile[];
  user?: ModelsUser;
  directory?: ModelsDirectory;
  ship_type?: ModelsShipType;
  mode?: ModelsUploadMode;
  in_format?: ModelsInputFormat;
  out_format?: ModelsOutputFormat;
}

export interface TypeUploadFileResponse {
  have_duplicated?: boolean;
  msg?: string;
  file_name?: string[];
}

export interface tn {
  id?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  name?: string;
  customer_name?: string;
  customer_address?: string;
  customer_telnumber?: string;
  customer_email?: string;
}

export interface CustomData {
  tenant_name?: string,
  name?: string,
  page_count?: number,
  invoice_count?: number,
}

export interface Tenant {
  Name?: string;
  Customer_Name?: string;
  Customer_Address?: string;
  Customer_Telnumber?: string;
  Customer_Email?: string;
}

export interface ModelsUserAdminTenent {
  created_at?: string;
  deleted_at?: GormDeletedAt;
  customer_email?: string;
  enable?: boolean;
  need_chpasswd?: boolean;
  first_name?: string;
  id?: number;
  last_name?: string;
  uauth?: ModelsUAuthentication;
  uauth_id?: number;
  updated_at?: string;
}
export interface WebProperty {
  id?: string;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  name: string;
  value: string;
  started_at?: string;
  expired_at?: string;
}


export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
              ? JSON.stringify(property)
              : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
          .then((data) => {
            if (r.ok) {
              r.data = data;
            } else {
              r.error = data;
            }
            return r;
          })
          .catch((e) => {
            r.error = e;
            return r;
          });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title G-RPA Orchestrator
 * @version 0.1.0
 * @license © 2022 G-Able Pub Co., Ltd. (https://www.g-able.com)
 * @baseUrl /api
 * @contact
 *
 * This is a G-RPA Orchestrator API
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  authentication = {
    /**
     * @description Check credential for get access and refresh token
     *
     * @tags Authentication
     * @name CheckCredential
     * @summary Check credential
     * @request POST:/authentication/check-credential
     */
    checkCredential: (params: TypesCredentialParams, requestParams: RequestParams = {}) =>
      this.request<TypesTokenResponse, GinH>({
        path: `/authentication/check-credential`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Revoke token
     *
     * @tags Authentication
     * @name GetCredential
     * @summary Revoke token
     * @request GET:/authentication/get-credential
     * @secure
     */
    getCredential: (params: RequestParams = {}) =>
      this.request<GinH, GinH>({
        path: `/authentication/get-credential`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    checkCredentialAdminTenent: (params: TypesCredentialParams, requestParams: RequestParams = {}) =>
      this.request<TypesTokenResponse, GinH>({
        path: `/authentication/check-credential-for-tenent`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),
    getCredentialAdminTenant: (params: RequestParams = {}) =>
      this.request<ModelsUserAdminTenent, GinH>({
        path: `/authentication/get-credentialForTenent`,
        method: "GET",
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),


  };
  directories = {
    /**
     * @description Create directory
     *
     * @tags Directories
     * @name CreateDirectory
     * @summary Create directory
     * @request POST:/directories/add
     * @secure
     */
    createDirectory: (Directory: ModelsDirectory, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/add`,
        method: "POST",
        body: Directory,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Delete directory
     *
     * @tags Directories
     * @name DeleteDirectory
     * @summary Delete directory
     * @request DELETE:/directories/delete/{directory_id}
     * @secure
     */
    deleteDirectory: (directoryId: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/directories/delete`,
        method: "DELETE",
        query: {
          directory_id: directoryId,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Update directory
     *
     * @tags Directories
     * @name UpdateDirectory
     * @summary Update directory
     * @request PUT:/directories/edit
     * @secure
     */
    updateDirectory: (Directory: ModelsDirectory, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/edit`,
        method: "PUT",
        body: Directory,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory
     *
     * @tags Directories
     * @name GetDirectoryAll
     * @summary Get directory
     * @request GET:/directories/get
     * @secure
     */
    getDirectoryAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory with offset
     *
     * @tags Directories
     * @name GetDirectoryOffset
     * @summary Get directory with offset
     * @request GET:/directories/get-with-offset/{offset}/{limit}
     * @secure
     */
    getDirectoryOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Get directory directory
     *
     * @tags Directories
     * @name GetDirectoryById
     * @summary Get directory by id
     * @request GET:/directories/get/{directory_id}
     * @secure
     */
    getDirectoryById: (directoryId: string, params: RequestParams = {}) =>
      this.request<ModelsDirectory, GinH>({
        path: `/directories/get`,
        method: "GET",
        query: {
          directory_id: directoryId,
        },
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Search directory directory
     *
     * @tags Directories
     * @name GetDirectorySearch
     * @summary Search directory
     * @request GET:/directories/search
     * @secure
     */
    getDirectorySearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/directories/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
  };
  roles = {
    /**
     * @description Get all roles
     *
     * @tags Roles
     * @name GetAllRoles
     * @summary Get all roles
     * @request GET:/roles/get-all
     * @secure
     */
    getAllRoles: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/roles/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getUserRoles: (params: RequestParams = {}) =>
      this.request<ModelsRole, GinH>({
        path: `/roles/get-user-roles`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  users = {
    /**
     * @description Create user
     *
     * @tags Users
     * @name CreateUser
     * @summary Create user
     * @request POST:/users/add
     * @secure
     */
    createUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Bring Your Own Password of user
     *
     * @tags Users
     * @name BringYourOwnPasswordUser
     * @summary Bring Your Own Password of user
     * @request POST:/users/bring-your-own-password
     * @secure
     */
    bringYourOwnPasswordUser: (params: TypesUserChangePasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/bring-your-own-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Change password of user
     *
     * @tags Users
     * @name ChangePasswordUser
     * @summary Change password of user
     * @request POST:/users/change-password
     * @secure
     */
    changePasswordUser: (params: TypesUserChangePasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/change-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Check reset secret
     *
     * @tags Users
     * @name CheckResetSecret
     * @summary Check reset secret
     * @request GET:/users/check-reset-secret/{secret}
     * @secure
     */
    checkResetSecret: (secret: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/users/check-reset-secret/${secret}`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * @description Delete user
     *
     * @tags Users
     * @name DeleteUser
     * @summary Delete user
     * @request DELETE:/users/delete/{id}
     * @secure
     */
    deleteUser: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/users/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Update user
     *
     * @tags Users
     * @name UpdateUser
     * @summary Update user
     * @request PUT:/users/edit
     * @secure
     */
    updateUser: (user: ModelsUser, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Forget password of user
     *
     * @tags Users
     * @name ForgetPasswordUser
     * @summary Forget password of user
     * @request POST:/users/forget-password
     */
    forgetPasswordUser: (params: TypesUserForgetPasswdParams, requestParams: RequestParams = {}) =>
      this.request<TypesForgetPasswordResponse, GinH>({
        path: `/users/forget-password`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description get-all-user
     *
     * @tags Users
     * @name GetAllUser
     * @summary get-all-user
     * @request GET:/users/get-all
     * @secure
     */
    getAllUser: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    getAllUserByTenantID: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-all-by-tennant`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),


    /**
     * @description Get user with offset
     *
     * @tags Users
     * @name GetUserOffset
     * @summary Get user with offset
     * @request GET:/users/get-with-offset/{offset}/{limit}
     * @secure
     */
    getUserOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-with-offset`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Get user directory
     *
     * @tags Users
     * @name GetUserById
     * @summary Get user by id
     * @request GET:/users/get/{id}
     * @secure
     */
    getUserById: (id: string, params: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/get`,
        method: "GET",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),

    /**
     * @description Reset password of user
     *
     * @tags Users
     * @name ResetPasswordUser
     * @summary Reset password of user
     * @request POST:/users/reset-password
     */
    resetPasswordUser: (params: TypesUserResetPasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/reset-password`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Reset password of user by administrator
     *
     * @tags Users
     * @name ResetPasswordUserByAdministrator
     * @summary Reset password of user by administrator
     * @request POST:/users/reset-password-by-administrator
     * @secure
     */
    resetPasswordUserByAdministrator: (params: TypesUserResetPasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/users/reset-password-by-administrator`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),

    /**
     * @description Search user
     *
     * @tags Users
     * @name GetUserSearch
     * @summary Search user
     * @request GET:/users/search
     * @secure
     */
    getUserSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getPageMonitor: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/get-page-monitor`,
        method: "GET",
        secure: true,
        query: {
          offset: offset,
          limit: limit
        },
        format: "json",
        ...params,
      }),
    getPageMonitorSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/users/search-page-monitor`,
        method: "GET",
        secure: true,
        query: query,
        format: "json",
        ...params,
      }),
  };
  facilities = {
    clearFreightTransactionByUploadID: (uploadID: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/clear-freight-transaction-by-uploadID`,
        method: "GET",
        secure: true,
        query: {
          upload_id: uploadID,
        },
        format: "json",
        ...params,
      }),
    clearFreightTransaction: (tenantId: string, start: string, end: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/clear-freight-transaction`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenantId,
          start_date: start,
          end_date: end
        },
        format: "json",
        ...params,
      }),
    getAllFormat: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-format`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllShipType: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-ship-type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllShipTypeWithOutSelect: (input_format_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/facilities/get-all-ship-type-without-selected`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          input_format_id: input_format_id
        },
        ...params,
      }),
  };
  tenants = {
    getAllTenant: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
    addTenents: (tenents: Tenant, params: RequestParams = {}) =>
      this.request<tn, GinH>({
        path: `/tenent/add`,
        method: "POST",
        body: tenents,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTenents: (tenents: any[], params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/delete`,
        method: "POST",
        body: tenents,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getDataCalculate: (start: string, end: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/get-data-calculate`,
        method: "GET",
        secure: true,
        query: {
          startdate: start,
          enddate: end
        },
        format: "json",
        ...params,
      }),
    getDetailData: (start: string, end: string, tenantId: string, directoryId: string, userId: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenent/get-detail-data`,
        method: "GET",
        secure: true,
        query: {
          startdate: start,
          enddate: end,
          tenant_id: tenantId,
          directory_id: directoryId,
          user_id: userId
        },
        format: "json",
        ...params,
      }),
    generatePDF: (tenantId: string, startdate: string, enddate: string, params: RequestParams = {}) =>
      this.request<ArrayBuffer, GinH>({
        path: `/tenent/generate-pdf`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenantId,
          startdate: startdate,
          enddate: enddate
        },
        format: "arrayBuffer",
        ...params,
      }),

  };
  admin = {
    getAllAdmin: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/admin-tenant/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    createUser: (user: ModelsUserAdminTenent, params: RequestParams = {}) =>
      this.request<ModelsUserAdminTenent, GinH>({
        path: `/admin-tenant/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    bringYourOwnPasswordUserAdmin: (params: TypesUserChangePasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUserAdminTenent, GinH>({
        path: `/admin-tenant/bring-your-own-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),
    updateUser: (user: ModelsUserAdminTenent, params: RequestParams = {}) =>
      this.request<ModelsUserAdminTenent, GinH>({
        path: `/admin-tenant/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteUser: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/admin-tenant/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),
    resetPasswordUser: (params: TypesUserResetPasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUser, GinH>({
        path: `/admin-tenant/reset-password`,
        method: "POST",
        body: params,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),
    changePasswordUser: (params: TypesUserChangePasswdParams, requestParams: RequestParams = {}) =>
      this.request<ModelsUserAdminTenent, GinH>({
        path: `/admin-tenant/change-password`,
        method: "POST",
        body: params,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...requestParams,
      }),
    getSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/admin-tenant/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
  };
  inputFormat = {
    getAllInputTemp: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllWithoutRbac: (tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-all-without-rbac`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenant_id
        },
        format: "json",
        ...params,
      }),
    createTemplate: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    editTemplate: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    updateEnable: (user: ModelsInputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/input-format/update`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTemplate: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/input-format/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-format/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
  }
  outputFormat = {
    getAllInputTemp: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/output-format/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getAllWithoutRbac: (tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/output-format/get-all-without-rbac`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenant_id
        },
        format: "json",
        ...params,
      }),
    createTemplate: (user: ModelsOutputFormat, params: RequestParams = {}) =>
      this.request<ModelsOutputFormat, GinH>({
        path: `/output-format/add`,
        method: "POST",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    editTemplate: (user: ModelsOutputFormat, params: RequestParams = {}) =>
      this.request<ModelsOutputFormat, GinH>({
        path: `/output-format/edit`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    updateEnable: (user: ModelsOutputFormat, params: RequestParams = {}) =>
      this.request<ModelsInputFormat, GinH>({
        path: `/output-format/update`,
        method: "PUT",
        body: user,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTemplate: (id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/output-format/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: id,
        },
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/output-format/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getSearch: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/output-format/search`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
  }
  tenantInputFormat = {
    getOffset: (offset: number, limit: number, tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenant-input-format/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
          tenant_id: tenant_id
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getInputFormatByTenant: (tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenant-input-format/get-all`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenant_id
        },
        format: "json",
        ...params,
      }),
    createTenantInputFormat: (inputFormat: ModelTenantOutputFormat[], params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/tenant-input-format/add`,
        method: "POST",
        body: inputFormat,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTenantInputFormat: (data: ModelTenantInputFormat, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/tenant-input-format/delete`,
        method: "DELETE",
        secure: true,
        query: {
          tenant_id: data.tenant_id,
          input_format_id: data.input_format_id,
        },
        format: "json",
        ...params,
      }),
  }
  tenantOutputFormat = {
    getOffset: (offset: number, limit: number, tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenant-output-format/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit,
          tenant_id: tenant_id
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getInputFormatByTenant: (tenant_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/tenant-output-format/get-all`,
        method: "GET",
        secure: true,
        query: {
          tenant_id: tenant_id
        },
        format: "json",
        ...params,
      }),
    createTenantOutputFormat: (inputFormat: ModelTenantOutputFormat[], params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/tenant-output-format/add`,
        method: "POST",
        body: inputFormat,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    deleteTenantOutputFormat: (data: ModelTenantOutputFormat, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/tenant-output-format/delete`,
        method: "DELETE",
        secure: true,
        query: {
          tenant_id: data.tenant_id,
          output_format_id: data.output_format_id,
        },
        format: "json",
        ...params,
      }),
  }
  webProperty = {
    getWebPropertyByID: (id: string, params: RequestParams = {}) =>
      this.request<WebProperty, GinH>({
        path: `/web-property/get-property`,
        method: "GET",
        secure: true,
        query: {
          id: id
        },
        format: "json",
        ...params,
      }),
    updateWebproperty: (WebProperty: WebProperty, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/web-property/update`,
        method: "PUT",
        body: WebProperty,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getSearchWebproperty: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/web-property/search-webproperty`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
    createWebProp: (web: WebProperty, params: RequestParams = {}) =>
      this.request<WebProperty, GinH>({
        path: `/web-property/create`,
        method: "POST",
        body: web,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/web-property/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit
        },
        secure: true,
        format: "json",
        ...params,
      }),
    deleteSystemProperty: (prop_id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/web-property/delete`,
        method: "DELETE",
        secure: true,
        query: {
          id: prop_id
        },
        format: "json",
        ...params,
      }),
  }
  support = {
    create: (user_id: string, support: ModelsSupport, allFile: File[], params: RequestParams = {}) =>
      this.request<TypeUploadFileResponse, GinH>({
        path: `/support/create`,
        method: "POST",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        body: {
          topic: support.topic,
          type: support.type,
          level: support.level,
          summary: support.summary,
          ...allFile
        },
        query: {
          user_id: user_id
        },
        ...params,
      }),
    getAll: (user_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          user_id: ""
        },
        ...params,
      }),
    getAllAdmin: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-all-admin`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    getOffset: (offset: number, limit: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/get-with-offset`,
        method: "GET",
        query: {
          offset: offset,
          limit: limit
        },
        secure: true,
        format: "json",
        ...params,
      }),
    getSearchTenant: (query: { search: string; offset: number; limit: number }, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/search-tenant`,
        method: "GET",
        query: {
          ...query
        },
        secure: true,
        format: "json",
        ...params,
      }),
    update: (support_id: string, level: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          support_id: support_id,
          level: level
        },
        ...params,
      }),
  };
  InputTemplateShipType = {
    create: (input_format_id: string, shipTypes: ModelsShipType[], params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-template-ship-type/add`,
        method: "POST",
        secure: true,
        type: ContentType.Json,
        format: "json",
        body: shipTypes,
        query: {
          input_format_id: input_format_id
        },
        ...params,
      }),
    getAllByInputFormat: (input_format_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/input-template-ship-type/get-all-by-input-format`,
        method: "GET",
        secure: true,
        type: ContentType.FormData,
        format: "json",
        query: {
          input_format_id: input_format_id
        },
        ...params,
      }),
    deleteShipTypeBinding: (input_format_id: string, ship_type_id: string, params: RequestParams = {}) =>
      this.request<string, GinH>({
        path: `/input-template-ship-type/delete`,
        method: "DELETE",
        secure: true,
        query: {
          ship_type_id: ship_type_id,
          input_format_id: input_format_id,
        },
        format: "json",
        ...params,
      }),
  };
  supportProgess = {
    create: (progessData: SupportProgess, params: RequestParams = {}) =>
      this.request<SupportProgess, GinH>({
        path: `/support-progess/create`,
        method: "POST",
        secure: true,
        type: ContentType.Json,
        format: "json",
        body: progessData,
        ...params,
      }),
    getAll: (support_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support-progess/get-by-id`,
        method: "GET",
        secure: true,
        format: "json",
        query: {
          support_id: support_id
        },
        ...params,
      }),
    update: (support_id: string, level: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/support/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          support_id: support_id,
          level: level
        },
        ...params,
      }),
  };
  holiday = {
    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/holiday/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    update: (holiday_id: string, days: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/holiday/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          id: holiday_id,
          days: days
        },
        ...params,
      }),
  };
  escalationLevels = {
    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/esc-level/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    update: (esc_level_id: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/esc-level/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          esc_level_id: esc_level_id,
        },
        ...params,
      }),
  };
  escalationEmail = {
    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/esc-mail/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    update: (esc_mail_id: string, mail_l1: string, mail_l2: string, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/esc-mail/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          id: esc_mail_id,
          mail_l1: mail_l1,
          mail_l2: mail_l2,
        },
        ...params,
      }),
  };
  level = {
    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/level/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    update: (id: string, time_l1: number, time_l2: number, params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/level/update`,
        method: "PUT",
        secure: true,
        format: "json",
        query: {
          id: id,
          time_l1: time_l1,
          time_l2: time_l2
        },
        ...params,
      }),
  };
  shiptype = {
    getAll: (params: RequestParams = {}) =>
      this.request<UtilsArrayResponse, GinH>({
        path: `/ship-type/get-all`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
    update: (name: string, id: string, params: RequestParams = {}) =>
      this.request<ModelsShipType, GinH>({
        path: `/ship-type/edit`,
        method: "PUT",
        secure: true,
        query: {
          name: name,
          id: id
        },
        format: "json",
        ...params,
      }),
    create: (name: string, params: RequestParams = {}) =>
      this.request<ModelsShipType, GinH>({
        path: `/ship-type/add`,
        method: "POST",
        secure: true,
        format: "json",
        query: {
          name: name
        },
        ...params,
      }),
  };
}





