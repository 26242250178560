import { createContext, useState, useEffect } from "react";
type ContextProps = {}

export const StrongPasswordValidatorContext = createContext<ContextProps | null>(null);
interface Properties {
    password: string
}

export const StrongPasswordValidator: React.FC<Properties> = ({ password }) => {
    const [validGteq8, setValidGteq8] = useState(false)
    const [validNumber, setValidNumber] = useState(false)
    const [validLower, setValidLower] = useState(false)
    const [validUpper, setValidUpper] = useState(false)
    const [validSpecial, setValidSpecial] = useState(false)

    useEffect(() => {
        let gteq8 = password.length >= 8
        let number = /\d/.test(password)
        let lower = /[a-z]/.test(password)
        let upper = /[A-Z]/.test(password)
        let special = /[-+_!@#$%^&*.,?]/.test(password)

        setValidGteq8(gteq8)
        setValidNumber(number)
        setValidLower(lower)
        setValidUpper(upper)
        setValidSpecial(special)
    }, [password])



    return (
        <StrongPasswordValidatorContext.Provider value={null}>
            <div>
                <p className={`pl-3 ${validGteq8 ? "text-green-700" : "text-red-600"}`}>
                    {validGteq8 &&
                        <i className="pi pi-check-circle"></i>
                    }
                    {!validGteq8 &&
                        <i className="pi pi-times-circle"></i>
                    }
                    <span className="ml-3">Longer than 8 characters</span>
                </p>
                <p className={`pl-3 ${validNumber ? "text-green-700" : "text-red-600"}`}>
                    {validNumber &&
                        <i className="pi pi-check-circle"></i>
                    }
                    {!validNumber &&
                        <i className="pi pi-times-circle"></i>
                    }
                    <span className="ml-3">Has an uppercase letter</span>

                </p>
                <p className={`pl-3 ${validLower ? "text-green-700" : "text-red-600"}`}>
                    {validLower &&
                        <i className="pi pi-check-circle"></i>
                    }
                    {!validLower &&
                        <i className="pi pi-times-circle"></i>
                    }
                    <span className="ml-3">Has a lowercase letter</span>

                </p>
                <p className={`pl-3 ${validUpper ? "text-green-700" : "text-red-600"}`}>
                    {validUpper &&
                        <i className="pi pi-check-circle"></i>
                    }
                    {!validUpper &&
                        <i className="pi pi-times-circle"></i>
                    }
                    <span className="ml-3">Has a number</span>

                </p>
                <p className={`pl-3 ${validSpecial ? "text-green-700" : "text-red-600"}`}>
                    {validSpecial &&
                        <i className="pi pi-check-circle"></i>
                    }
                    {!validSpecial &&
                        <i className="pi pi-times-circle"></i>
                    }
                    <span className="ml-3">Has a special case</span>

                </p>
            </div>
        </StrongPasswordValidatorContext.Provider>
    );
}

export const isStrongPassword = (password: string) => {

    const gteq8 = password.length >= 8
    const number = /\d/.test(password)
    const lower = /[a-z]/.test(password)
    const upper = /[A-Z]/.test(password)
    const special = /[-+_!@#$%^&*.,?]/.test(password)

    return gteq8 && number && lower && upper && special
}