import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Fragment, useEffect, useState } from "react";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { ModelsInputFormat, ModelsOutputFormat, ModelsUserAdminTenent } from "../api";
import { useLocation } from "react-router-dom";
import EditUserDialog from "../components/Dialog/EditUserDialog";
import CustomDeleteDialog from "../components/Dialog/CustomDeleteDialog";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import SearchDialog from '../components/SearchDialog';
import AddUserDialog from "../components/Dialog/AddUserDialog";
import { useToast, usePageWaiting } from "../utils/Properties";
import AddTemplateDialog from "../components/Dialog/AddTemplateDialog";
import { Dropdown } from "primereact/dropdown"
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";

const OutputFormatTemplate = () => {
    const apiInstance = useSecureApiConnector();
    const [data, setData] = useState([] as ModelsOutputFormat[]);
    const [createTmp, setcreateTmp] = useState([] as ModelsOutputFormat[]);
    const [initData, setInitData] = useState([] as ModelsOutputFormat[]);
    const setPageWaiting = usePageWaiting()
    const [confirmEnable, setConfirmEnable] = useState(false)
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [objectState, setObjectState] = useState({} as ModelsOutputFormat)
    const [dialogEdit, setDialogEdit] = useState(false)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUserAdminTenent)
    const [objectDeleteTemplte, setObjectDeleteTemplte] = useState({} as ModelsOutputFormat)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [objectResetPasswdState, setObjectResetPasswdState] = useState({} as ModelsUserAdminTenent)
    const [dialogDelete2, setDeleteDialog2] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [filter, setFilter] = useState("")
    const [dialogSearch, setSearchDialog] = useState(false)
    const [successToast, errorToast, ,] = useToast();
    const [objectEdit, setObjectEdit] = useState({} as ModelsInputFormat)
    const [checked1, setChecked1] = useState(false);
    const [updateEnable, setUpdateEnable] = useState({} as ModelsOutputFormat)
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const fetchData = () => {
        setLoading(true)
        apiInstance.outputFormat.getOffset(lazyParams.first, lazyParams.rows)
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!)
                setData(data.record_list);
                setInitData(data.record_list)
                setTriggerFetch(true);
                setLoading(false);
            })
    };

    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }

    useEffect(() => {
        setLoading(true);
        let sfilter = filter;
        if (moment(filter, "MMMM Do YYYY", true).isValid()) {
            sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        }

        apiInstance.outputFormat
            .getSearch({
                search: sfilter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    }, [lazyParams]);

    const onSubmitEnableOrDisable = async (data: any) => {
        setPageWaiting(true)
        updateEnable.enable = checked1
        setConfirmEnable(false)
        await apiInstance.outputFormat.updateEnable(updateEnable)
            .then(() => { successToast(`${checked1 ? "enable" : "disable"} success`); fetchData() })
            .catch(() => { errorToast(`${checked1 ? "enable" : "disable"} failed`); setPageWaiting(false) })
        setPageWaiting(false)
    }

    const onSubmit = (data: any) => {

        apiInstance.outputFormat.deleteTemplate(objectDeleteTemplte.id!.toString())
            .then(() => {
                successToast("template deleted")
                setObjectDeleteState({})
                setDeleteDialog(false)
                refresh()
            })
            .catch(() => {
                errorToast("template delete failed")
                setObjectDeleteState({})
            })
    }

    const createTemplate = (data: any) => {

        apiInstance.outputFormat.createTemplate({
            name: data.name
        })
            .then(() => {
                successToast("template added")
                setDialogAdd(false)
                refresh()
            })
            .catch(() => {
                errorToast("add template failed")
                setDialogAdd(false)
            })
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch])

    useEffect(() => {
        refresh();
    }, [lazyParams]);

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const EditTemplate = (data: any) => {

        apiInstance.outputFormat.editTemplate({
            id: objectEdit.id,
            name: data.name,
            created_at: objectEdit.created_at
        })
            .then(() => {
                successToast("template edited")
                setDialogEdit(false)
                refresh()
            })
            .catch(() => {
                errorToast("edit template failed")
                setDialogAdd(false)
            })
    }

    useEffect(() => {

        if (filter === " " && data !== initData) {
            setData(initData);
            setTotalRecords(initData.length)

        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.outputFormat
                .getSearch({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setData([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    return (
        <>
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmit} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteTemplte.name}</b> &nbsp;template? </>} />
            <AddTemplateDialog data={createTmp} setData={setcreateTmp} visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} onSubmit={createTemplate} />
            <AddTemplateDialog data={objectEdit} setData={setObjectEdit} visible={dialogEdit} setVisible={setDialogEdit} refresh={refresh} onSubmit={EditTemplate} edit={true} editTiltle="Edit Template" />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={confirmEnable} setVisible={setConfirmEnable} refresh={refresh}
                onSubmit={onSubmitEnableOrDisable} meessage={<>Confirm to {checked1 ? "enable" : "disable"} this&nbsp;template? </>} customTitle={`${checked1 ? "Enable" : "Disable"} CONFIRMATION`} />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                <Card className="card-pad" title="OutputFormat Management">
                    <div className='w-full relative' style={{ height: "48px", marginBottom: "1rem" }}>
                        <Button
                            icon="mdi mdi-plus-circle-outline"
                            onClick={() => setDialogAdd(true)}
                            style={{ left: '0rem', top: '5px' }}
                            className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                            aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add output template</Button>
                        <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                            <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" />
                            <i className="pi pi-search gable-text-dark-blue" />

                        </span>
                        <Button
                            icon="mdi mdi-magnify"
                            style={{ right: '1rem', top: '5px' }}
                            className="absolute p-button-rounded p-button-secondary flex md:hidden "
                            onClick={() => { setSearchDialog(true) }}
                        />
                    </div>
                    <DataTable
                        value={data}
                        lazy
                        loading={loading}
                        paginator
                        responsiveLayout="scroll"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={(e: any) => {
                            setLazyParams(e);
                        }}
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="justify-content-end"
                        tableStyle={{ minWidth: "100%" }}
                    >
                        <Column
                            field="id"
                            header="ID"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column
                            field="name"
                            header="Name"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column header="Status" body={(item: ModelsUserAdminTenent) => (<>
                            <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                        </>)}></Column>
                        <Column className='text-right' body={(item) => (
                            <span className="p-buttonset flex">
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <InputSwitch checked={item.enable} onChange={(e) => { setChecked1(e.value); setUpdateEnable(item); setConfirmEnable(true) }} />
                                </div>
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        className='transition-duration-200 hover:bg-indigo-700 cursor-pointer'
                                        src={require("../assets/Group 36.png")}
                                        alt="pencil"
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        onClick={() => {
                                            setObjectEdit(item)
                                            setDialogEdit(true)
                                        }}
                                    />
                                </div>
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        className={'transition-duration-200 cursor-pointer  hover:bg-red-500'}
                                        src={require("../assets/Group 32.png")}
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        alt="bin"
                                        onClick={() => {
                                            setObjectDeleteTemplte(item)
                                            setDeleteDialog(true)

                                        }}
                                    />
                                </div>
                            </span>

                        )}></Column>
                    </DataTable>
                </Card>
            </div>
        </>
    );
};
export default OutputFormatTemplate;
