import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";


const Layout = () => {
    return (
        <div className="p-0 m-0">
            <Navbar />
            <div className="p-0">
                <Outlet />
            </div>
            <div className="mt-5 mb-3 text-center text-sm gable-text-gray">
                &copy; {new Date().getFullYear()} G-ABLE PUBLIC COMPANY LIMITED.
            </div>

        </div>
    )
}
export default Layout;