import { useEffect, useState } from "react";
import { Menubar } from "primereact/menubar";
import { Sidebar } from "primereact/sidebar";
import { Avatar } from "primereact/avatar";
import { Divider } from "primereact/divider";
import { useNavigate, useLocation } from "react-router-dom";
import "./style.css"
import "../../global.css"
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { useCookies } from "react-cookie";
import { useCheckAdmin, useDirectory, useIssue, useToast } from "../../utils/Properties";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import { isStrongPassword, StrongPasswordValidator } from "../../utils/StrongPasswordVelidator";
import { Controller, useForm } from "react-hook-form";
import { Password } from "primereact/password";
import { Dropdown } from "primereact/dropdown";
import { ModelsDirectory, ModelsRole, ModelsUserAdminTenent } from "../../api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { ToggleButton } from 'primereact/togglebutton';


const getCookie = (cookieName: string) => {
    let cookie = {} as any;
    document.cookie.split(';').forEach(function (el) {
        let [key, value] = el.split('=');
        cookie[key.trim()] = value;
    })
    return cookie[cookieName];
}

const Navbar = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['api-token-admin', 'token-expires-admin', 'need-chpasswd-admin', "directory-id", "tenant-id"]);
    const navigate = useNavigate()
    const apiInstance = useSecureApiConnector()
    // const issue = useIssue()
    const [menu, setMenu] = useState<any[]>([]);
    const checkAdmin = useCheckAdmin()
    const [isAuthorized, setIsAuthorized] = useState(false)
    const location = useLocation();
    const defaultAdminTenent: ModelsUserAdminTenent = {
        created_at: '',
        deleted_at: { time: new Date().toISOString(), valid: true },
        customer_email: '',
        enable: true,
        first_name: '',
        id: 1,
        last_name: '',
        uauth: {},
        uauth_id: 1,
        updated_at: '',
    }
    const [issue, setIssue] = useState(defaultAdminTenent)
    const [checked2, setChecked2] = useState(false);

    const getCredential = () => {

        apiInstance.authentication.getCredentialAdminTenant()
            .then(res => res.data)
            .then(res => {
                setIssue(res)
                setIsAuthorized(true)
            })
            .catch(() => {
                removeCookie("api-token-admin")
                setIsAuthorized(false)
                navigate("/sign-in")
            })
    }

    useEffect(() => {
        if (location.pathname !== 'sign-in') {
            getCredential()
            getWebProperty()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const items = [

        {
            label: "Price Management",
            icon: "mdi mdi-cash-multiple",
            url: "/PriceManagement",
        },
        {
            label: "User Admin Management",
            icon: "mdi mdi-account-group",
            url: "/add-user-admin",
        },
        {
            label: "Template Management",
            icon: "mdi mdi-file-multiple",
            url: "/manage-template",
        },
        {
            label: "Template ACL",
            icon: "mdi mdi-file-multiple",
            url: "/manage-rbac",
        },
        {
            label: "Support Management",
            icon: "mdi mdi-help-box-multiple-outline",
            url: "/SupportManagement",
        },
        {
            label: "System Properties",
            icon: "mdi mdi-help-box-multiple-outline",
            url: "/system-management",
        },
        {
            label: "Escalation Management",
            icon: "mdi mdi-account-alert",
            url: "/escalation-management",
        }
    ];

    const getWebProperty = () => {
        apiInstance.webProperty.getWebPropertyByID("9a6f65d6-8dc7-40ca-b4e0-93515e9f6ed9").then(res => {
            if (res.data.value !== "disable") {
                setChecked2(true)
            } else {
                setChecked2(false)
            }

        })
    }

    const enableMaintanacePage = () => {
        console.log(checked2);

        if (!checked2) {
            apiInstance.webProperty.updateWebproperty({
                id: "9a6f65d6-8dc7-40ca-b4e0-93515e9f6ed9",
                name: "maintanace",
                value: "maintanacing",
            }).then(res => res.data)
        } else {
            apiInstance.webProperty.updateWebproperty({
                id: "9a6f65d6-8dc7-40ca-b4e0-93515e9f6ed9",
                name: "maintanace",
                value: "disable",
            }).then(res => res.data)
        }


    }

    const end = () => (
        <>
            {/* <ToggleButton checked={checked2} onChange={(e) => {
                setChecked2(e.value)
                enableMaintanacePage()
            }}
                onLabel="maintanace"
                offLabel="online" onIcon="pi pi-exclamation-triangle" offIcon=" "
                className="w-full sm:w-10rem " aria-label="Confirmation"
                style={{
                    borderRadius: "17px", marginRight: "2rem",
                    backgroundColor: checked2 ? "#e5ad46" : "#49e546", borderColor: checked2 ? "#e5ad46" : "#49e546",
                    color: checked2 ? "" : "#fff"
                }}
                onMouseEnter={(e) => {
                    if (checked2) {
                        e.currentTarget.style.backgroundColor = "#f2b646";
                    } else {
                        e.currentTarget.style.backgroundColor = "#49f246";
                    }
                }}

                onMouseLeave={(e) => {
                    // Restore the original background color when mouse leaves
                    e.currentTarget.style.backgroundColor = checked2 ? "#e5ad46" : "#49e546";
                }}
            >

            </ToggleButton> */}
            <Avatar label={issue.first_name && issue.last_name
                ? `${issue.first_name![0].toLocaleUpperCase()}${issue.last_name![0].toLocaleUpperCase()}`
                : `GA`} className="mr-2 cursor-pointer custom-avatar transition-duration-300" onClick={() => setVisibleRight(true)} size="large" shape="circle" />
        </>
    );
    const start = () => (
        <div><Button label="G-ABLE" onClick={() => navigate("/dashboard")}
            className="p-button-text gable-text-orange text-2xl p-0 mr-4" /></div>
    );
    const [visibleRight, setVisibleRight] = useState(false);

    const revokeAuthorized = () => {

        removeCookie("api-token-admin")
        removeCookie("token-expires-admin")
        removeCookie("need-chpasswd-admin")
        navigate("/sign-in")

    }
    const [visibleChangePasswd, setVisibleChangePasswd] = useState(false);

    const defaultValues = {
        oldPassword: '',
        password: '',
        passwordAgain: '',
    }

    const [successToast, errorToast, ,] = useToast();
    const { control, watch, formState: { errors }, handleSubmit, getValues, reset } = useForm({ defaultValues });
    const chpasswd = (data: any) => {
        apiInstance.admin.changePasswordUser({
            u_id: issue.id?.toString(),
            new_password: data.password,
            old_password: data.oldPassword
        })
            .then(() => {
                successToast("password changed")
                reset()
                setVisibleChangePasswd(false)
            })
            .catch(() => {
                errorToast("change password failed")
            })
    }



    return (
        <>
            <Dialog
                showHeader={false}
                closable={false}
                visible={visibleChangePasswd}
                onHide={() => {
                    setVisibleChangePasswd(false)
                    reset()
                }}
                className="custom-dialog w-23rem md:w-26rem"
            >
                <>
                    <div className="w-full gable-bg-gray py-2 px-4">
                        <div className="grid grid-nogutter">
                            <div className="col-10 text-xl hidden md:flex text-white relative">
                                {"Change password".toUpperCase()}
                            </div>
                            <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                                {"Change password".toUpperCase()}
                            </div>
                            <div className="col-2 text-right">
                                <Badge value="X"
                                    className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                    severity="danger"
                                    onClick={() => {
                                        setVisibleChangePasswd(false)
                                        reset()
                                    }}
                                ></Badge>
                            </div>
                        </div>
                    </div>
                    <div className='p-0'>
                        <form onSubmit={handleSubmit(chpasswd)} className="p-fluid p-3" style={{ maxWidth: '400px' }} >

                            <Controller name="oldPassword" control={control} rules={{ required: 'Current password is required.' }} render={({ field, fieldState }) => (
                                <div className="p-inputgroup m-2">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key" />
                                    </span>
                                    <Password
                                        id={field.name} {...field}
                                        placeholder="Current Password"
                                        feedback={false}
                                        className={!!errors.oldPassword ? 'p-invalid' : ''}
                                        toggleMask
                                    />
                                </div>
                            )} />

                            <Controller name="password" control={control} rules={{
                                required: 'Password is required.',
                                validate: (passwd) => (watch().oldPassword !== passwd)
                            }} render={({ field, fieldState }) => (
                                <div className="p-inputgroup m-2">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key" />
                                    </span>
                                    <Password
                                        id={field.name} {...field}
                                        placeholder="Password"
                                        feedback={false}
                                        className={!!errors.password ? 'p-invalid' : ''}
                                        toggleMask
                                    />
                                </div>
                            )} />

                            <Controller name="passwordAgain" control={control} rules={{
                                required: 'Password Agian is required.',
                                validate: (passwd) => (watch().password == passwd)
                            }} render={({ field, fieldState }) => (
                                <div className="p-inputgroup m-2">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-key" />
                                    </span>
                                    <Password
                                        id={field.name} {...field}
                                        placeholder="Password Again"
                                        feedback={false}
                                        className={!!errors.passwordAgain ? 'p-invalid' : ''}
                                        toggleMask
                                    />
                                </div>
                            )} />


                            <Button
                                label="Set Password"
                                type="submit"
                                className="p-button m-2"
                                disabled={!(isStrongPassword(watch().password) && !!watch().oldPassword && watch().password == watch().passwordAgain)}
                            />


                        </form>
                        <div className="p-3">
                            <StrongPasswordValidator password={getValues("password")} />

                            {(errors.oldPassword || errors.password || errors.passwordAgain) &&
                                <>

                                    <Divider />
                                    <div className="pl-2">
                                        <p className="p-error font-bold">Hint</p>
                                        <p className="m-0 p-error">{errors.oldPassword ? <small>{errors.oldPassword.message}</small> : <></>}</p>
                                        <p className="m-0 p-error">{errors.password ? <small>{errors.password.message ? errors.password.message : "New password cannot be the current password"}</small> : <></>}</p>
                                        <p className="m-0 p-error">{errors.passwordAgain ? <small>{errors.passwordAgain.message ? errors.passwordAgain.message : "Password not match"}</small> : <></>}</p>
                                    </div>

                                </>

                            }
                        </div>

                    </div>


                </>
            </Dialog>



            <Sidebar
                visible={visibleRight}
                position="right"
                onHide={() => setVisibleRight(false)}
            >
                <div className="text-center">
                    <Avatar label={
                        issue.first_name && issue.last_name
                            ? `${issue.first_name![0].toLocaleUpperCase()}${issue.last_name![0].toLocaleUpperCase()}`
                            : `GA`
                    } className="mb-3 gable-bg-orange text-white" size="xlarge" shape="circle" />
                    <p className="m-0">{issue.first_name} {issue.last_name}</p>
                    <p className="m-0 text-sm">{issue.customer_email}</p>
                </div>
                <Divider align="left" type="dashed" className="mt-4 mb-0">
                    <span className="text-sm">Informations</span>
                </Divider>
                <div className="mt-3 pl-4 text-sm">
                    <p className="mx-0 mb-1 mt-0 gable-text-gray"><b>User:</b> {!!issue.first_name ? issue.first_name + " " + issue.last_name : "Default"}</p>
                    <p className="mx-0 mb-1 mt-0 gable-text-gray"><b>Email:</b> {!!issue.customer_email ? issue.customer_email : "Default"}</p>
                    <Divider />
                </div>

                <Divider align="left" type="dashed" className="mt-4 mb-0">
                    <span className="text-sm">Preferences</span>
                </Divider>
                <div className="mt-4 text-center">
                    <span className="p-buttonset">
                        <Button label="Change Password" className="text-xs p-button-primary" icon="pi pi-key" onClick={() => {
                            setVisibleChangePasswd(true)
                        }} />
                        <Button label="Sign Out" className="text-xs p-button-danger" icon="pi pi-sign-out" onClick={() => {
                            revokeAuthorized()
                        }} />
                    </span>
                </div>
            </Sidebar>


            < Menubar
                className="py-0 px-3 gable-bg-gray-100 h-4rem sticky top-0"
                start={start}
                model={items ? items : []}
                end={end}
                style={{ width: "100%", zIndex: 100 }}
            />



        </>
    );
};

export default Navbar;