import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Fragment, useEffect, useState } from "react";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { ModelTenant, ModelTenantInputFormat, ModelsInputFormat, ModelsUserAdminTenent } from "../api";
import CustomDeleteDialog from "../components/Dialog/CustomDeleteDialog";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useToast } from "../utils/Properties";
import AddTemplateDialog from "../components/Dialog/AddTemplateDialog";
import { useNavigate, useLocation } from "react-router-dom";
import AddRbacInputDialog from "../components/Dialog/AddRbacInputDialog";
import ViewRbacDialog from "../components/Dialog/ViewRbacDialog";
import { Dropdown } from "primereact/dropdown";
import SearchDialog from "../components/SearchDialog";
import moment from "moment";


const InputFormatRBAC = () => {
    const apiInstance = useSecureApiConnector();
    const [data, setData] = useState([] as ModelTenant[]);
    const [createTmp, setcreateTmp] = useState([] as ModelsInputFormat[]);
    const [initData, setInitData] = useState([] as ModelsInputFormat[]);

    const [loading, setLoading] = useState(true);
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUserAdminTenent)
    const [objectDeleteTemplte, setObjectDeleteTemplte] = useState({} as ModelTenant)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [dialogView, setDialogView] = useState(false)
    const [filter, setFilter] = useState("")
    const [dialogSearch, setSearchDialog] = useState(false)
    const [successToast, errorToast, ,] = useToast();
    const location = useLocation();
    const [tenantId, setTenantId] = useState("")
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);


    const fetchData = () => {
        apiInstance.tenants
            .getOffset(lazyParams.first, lazyParams.rows)
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setData(data.record_list);
                setInitData(data.record_list)
                setTriggerFetch(true);
                setLoading(false);
            })
    };

    useEffect(() => {
        setLoading(true)
        fetchData()

    }, [triggerFetch]);

    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }

    const onSubmitDel = (data: any) => {
        console.log(data);

        // apiInstance.inputFormat.deleteTemplate(objectDeleteTemplte.id!.toString())
        //     .then(() => {
        //         successToast("template deleted")
        //         setObjectDeleteState({})
        //         setDeleteDialog(false)
        //         refresh()
        //     })
        //     .catch(() => {
        //         errorToast("template delete failed")
        //         setObjectDeleteState({})
        //     })
    }

    const createTemplate = (data: any) => {
        const addListRbac: ModelTenantInputFormat[] = []
        createTmp.forEach(input => {
            const addRbac: ModelTenantInputFormat = {
                input_format_id: input.id,
                tenant_id: tenantId
            }
            addListRbac.push(addRbac)
        });

        apiInstance.tenantInputFormat.createTenantInputFormat(addListRbac)
            .then(() => {
                successToast("RBAC added")
                setDialogAdd(false)
                refresh()
            })
            .catch(() => {
                errorToast("add RBAC failed")
                setDialogAdd(false)
            })
    }

    useEffect(() => {
        if (location.pathname !== "/sign-in") {
            setLoading(true)
            fetchData()
        }
    }, [location.pathname])

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    useEffect(() => {

        if (filter === " " && data !== initData) {
            setData(initData);
            setTotalRecords(initData.length)

        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.tenants
                .getSearch({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setData([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    useEffect(() => {
        let sfilter = filter;
        if (moment(filter, "MMMM Do YYYY", true).isValid()) {
            sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        }

        apiInstance.tenants
            .getSearch({
                search: sfilter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    }, [lazyParams]);

    return (
        <>
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmitDel} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteTemplte.name}</b> &nbsp;template? </>} />
            <SearchDialog visible={dialogSearch} setVisible={setSearchDialog} filter={filter} setFilter={setFilter} />
            <AddRbacInputDialog data={createTmp} setData={setcreateTmp} visible={dialogAdd}
                setVisible={setDialogAdd} refresh={refresh} onSubmit={createTemplate}
                titleName="Add RBac InputFormat Template" fieldName="InputFormat Name" tenant_id={tenantId} />
            <ViewRbacDialog data={createTmp} setData={setcreateTmp} visible={dialogView}
                setVisible={setDialogView} refresh={refresh} onSubmit={createTemplate}
                titleName="View RBac InputFormat Template" tenant_id={tenantId} />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                <Card className="card-pad" title="InputFormat ACL">
                    <div className='w-full relative' style={{ height: "48px", marginBottom: "1rem" }}>
                        {/* <Button
                            icon="mdi mdi-plus-circle-outline"
                            // onClick={(event: any) => menu.current.toggle(event)}
                            onClick={() => setDialogAdd(true)}
                            style={{ left: '0rem', top: '5px' }}
                            className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                            aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add input template</Button> */}
                        <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                            <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" />
                            <i className="pi pi-search gable-text-dark-blue" />

                        </span>
                        <Button
                            icon="mdi mdi-magnify"
                            style={{ right: '1rem', top: '5px' }}
                            className="absolute p-button-rounded p-button-secondary flex md:hidden "
                            onClick={() => { setSearchDialog(true) }}
                        />
                    </div>
                    <DataTable
                        value={data}
                        lazy
                        loading={loading}
                        paginator
                        responsiveLayout="scroll"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={(e: any) => {
                            setLazyParams(e);
                        }}
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="justify-content-end"
                        tableStyle={{ minWidth: "100%" }}
                    >
                        <Column
                            field="id"
                            header="ID"
                            style={{ width: "30%" }}
                        ></Column>
                        <Column
                            field="name"
                            header="Name"
                            style={{ width: "30%" }}
                        ></Column>
                        <Column field="customer_email" header="Customer Email" style={{ width: '30%' }}></Column>

                        <Column className='text-right'
                            body={(item) => (

                                <span className=" flex">

                                    <div
                                        className="align-items-center justify-content-center "
                                        style={{ width: "5.5rem", marginRight: '1rem' }}
                                    >
                                        <Button label="Add" className="p-button-outlined"
                                            style={{ borderRadius: '1rem', width: '6rem', height: '2rem', marginTop: '0.5rem' }}
                                            onClick={() => {
                                                setTenantId(item.id)
                                                setDialogAdd(true)
                                            }}
                                        />
                                    </div>

                                    <div
                                        className="align-items-center justify-content-center "
                                        style={{ width: "5.5rem", marginRight: '1rem' }}
                                    >
                                        <Button label="View" className="p-button-outlined p-button-help"
                                            style={{ borderRadius: '1rem', width: '6rem', height: '2rem', marginTop: '0.5rem' }}
                                            onClick={() => {
                                                setTenantId(item.id)
                                                setDialogView(true)
                                            }} />
                                    </div>
                                </span>
                            )}
                        ></Column>
                    </DataTable>
                </Card>
            </div>
        </>
    );
};
export default InputFormatRBAC;
