import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from "react"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import "./style.css"
import { ModelsUpload, UserTotalPageDetail } from "../../api"
import { Card } from "primereact/card"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import moment from "moment"
import { Navigate, useLocation } from "react-router-dom"
import { useCheckAdmin } from "../../utils/Properties"
import { Dropdown } from "primereact/dropdown"
import { Calendar, CalendarValueType } from "primereact/calendar";

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    title_name: string
    user_id: string
    setUserId: Dispatch<SetStateAction<string>>
    tenant_id: string
    setTenantId: Dispatch<SetStateAction<string>>
    directory_id: string
    setDirectoryId: Dispatch<SetStateAction<string>>

    start_date: string
    end_date: string
}

const TotalDetailDialog: FC<props> = ({ visible, setVisible, refresh,setTenantId,setDirectoryId,setUserId, title_name, start_date, end_date, tenant_id, directory_id, user_id }) => {
    const apiInstance = useSecureApiConnector()
    const title = title_name
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const isAdmin = useCheckAdmin();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const [initData, setInitData] = useState([] as ModelsUpload[]);
    const [data, setData] = useState([] as ModelsUpload[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [date10, setDate10] = useState<Date | Date[] | undefined>(undefined);

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };



    const getOffsetLikeYear = () => {
        setLoading(true);
        apiInstance.tenants
            .getDetailData(start_date, end_date, tenant_id, directory_id, user_id)
            .then((res) => res.data)
            .then((data) => {
                setInitData(data.record_list);
                setData(data.record_list);
                setTotalRecords(data.record_count!);
                setLoading(false);
            })
            .catch(() => {
            });
    }

    useEffect(() => {

        if (visible) {
            setLoading(true);

            getOffsetLikeYear()

            setInterval(() => {
                if (location.pathname === "/dashboard" && !isAdmin) {
                    refresh();
                }
            }, 10000);

        }
    }, [visible]);

    return (
        <>

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-dialog-70 p-0 m-0 w-50rem dialog-width2"
                showHeader={false}
                onHide={() => {
                    setTenantId("")
                    setDirectoryId("")
                    setUserId("")
                    setVisible(false)
                    setData([])
                    
                }}
            >

                <div className="w-full gable-bg-gray py-2 px-4">
                    <div className="grid grid-nogutter">
                        <div className="col-10 text-xl hidden md:flex text-white relative">
                            {title_name.toLocaleUpperCase()}
                        </div>
                        <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                            {title_name.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    setData([])
                                    refresh()
                                }}
                            ></Badge>
                        </div>
                    </div>
                </div>

                <div className="w-full py-2 px-4">
                    <Card>
                        <DataTable
                            className="text-v2"
                            value={data}
                            lazy
                            loading={loading}
                            responsiveLayout="scroll"
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={totalRecords}
                            onPage={(e: any) => {
                                setLazyParams(e);
                            }}
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                        >
                            <Column
                                header="Timestamp"
                                field="updated_at"
                                style={{
                                    minWidth: "200px",
                                    height: "95px",
                                }}
                                body={(item) => (
                                    <>{moment(item.updated_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                                )}
                            ></Column>
                            <Column
                                style={{
                                    width: "170px",
                                    height: "95px",
                                }}
                                header="Upload By"
                                field="upload_by"
                                body={(item: ModelsUpload) => (
                                    <>
                                        {item.user?.first_name} {item.user?.last_name}
                                    </>
                                )}
                            ></Column>
                            <Column
                                header="Branch"
                                field="user.directory.name"
                                style={{
                                    minWidth: "120px",
                                    height: "95px",
                                }}
                            ></Column>
                            <Column
                                header="Name"
                                field="name"
                                style={{
                                    width: "140px",
                                    height: "95px",
                                }}
                            ></Column>
                            <Column
                                field="ship_type.name"
                                header="Transport"
                                style={{
                                    minWidth: "100px",
                                    height: "95px",
                                }}
                            ></Column>
                            <Column
                                field="mode.name"
                                header="Type"
                                style={{
                                    width: "100px",
                                    height: "95px",
                                }}
                            ></Column>
                            <Column
                                field="format"
                                header="Input / Output"
                                body={(item: ModelsUpload) => (
                                    <>
                                        {item.in_format?.name} / {item.out_format?.name}
                                    </>
                                )}
                                style={{
                                    width: "120px",
                                    height: "95px",
                                }}
                            ></Column>


                        </DataTable>
                    </Card>
                </div>
            </Dialog>
        </>
    )
}

export default TotalDetailDialog;