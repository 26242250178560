import React from 'react';
import ReactDOM from 'react-dom/client';
import './global.css';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';
import ApiProvider from './utils/ApiConnector';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppPropertiesProvider from './utils/Properties';
import SignIn from './views/SignIn';
import Status403 from './views/StatusPage/403';
import Status404 from './views/StatusPage/404';
import ResetPasswd from './views/ResetPassword';
import Loading from './views/Landing';
import Layout from './components/Layout';
import Dashboard from './views/Dashboard';
import PriceManagement from './views/PriceManagement';
import AddUserAdmin from './views/AddUserAdmin';
import '@mdi/font/css/materialdesignicons.css'
import ManageTemplate from './views/ManageTemplate';
import ManageRBAC from './views/ManageRBAC';
import Support from './views/Support/Support';
import SystemMangement from './views/SystemMangement/SystemMangement';
import EscalationMangement from './views/Escalation/Escalation';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <CookiesProvider>
    <ApiProvider>
      <BrowserRouter>
        <AppPropertiesProvider>
          <Routes>
            <Route path="/sign-in" element={<SignIn />} />
            {/* <Route path="/reset-password" element={<ResetPasswd />} />
            <Route path="/forbidden" element={<Status403 />} />
            <Route path="/not-found" element={<Status404 />} />
            <Route path='/' element={<Loading />} /> */}
            <Route element={<Layout />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/PriceManagement" element={<PriceManagement />} />
              <Route path="/add-user-admin" element={<AddUserAdmin />} />
              <Route path="/manage-template" element={<ManageTemplate />} />
              <Route path="/manage-rbac" element={<ManageRBAC />} />
              <Route path="/SupportManagement" element={<Support />} />
              <Route path="/system-management" element={<SystemMangement />} />
              <Route path="/escalation-management" element={<EscalationMangement />} />
              {/* <Route path="/user-management" element={<UsersManagement />} />
              <Route path='/admin-page-monitor' element={<AdminPageMontorDashboard />} /> */}
            </Route>
            {/* <Route path='*' element={<Navigate to="/not-found" replace />} /> */}
            <Route path="/" element={<Navigate to="/sign-in" />} />
            {/* <Route path="/" element={<Navigate to="/dashboard" />} /> */}

          </Routes>
        </AppPropertiesProvider>
      </BrowserRouter>
    </ApiProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
