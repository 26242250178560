import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { Dropdown } from "primereact/dropdown"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelsDirectory, ModelsUser, ModelsUserAdminTenent } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Checkbox } from 'primereact/checkbox'
import "./style.css"
import { RadioButton } from "primereact/radiobutton"

interface props {
    data: ModelsUserAdminTenent
    setData: Dispatch<SetStateAction<ModelsUserAdminTenent>>
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
}

const EditDirectoryDialog: FC<props> = ({ data, setData, visible, setVisible, refresh }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Edit User Admin"
    const [successToast, errorToast, ,] = useToast();
    const setPageWaiting = usePageWaiting()
    const defaultValues = {
        first_name: '',
        last_name: '',
        customer_email: '',
        enable: false,
    }

    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });
    const onSubmit = (submitData: any) => {
        
        setPageWaiting(true)
        apiInstance.admin.updateUser({
            ...data,
            first_name: submitData.first_name,
            last_name: submitData.last_name,
            enable: submitData.enable,
        })
            .then(() => {
                successToast("user updated")
                setData({})
                setVisible(false)
                reset()
                refresh()
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast("user update failed")
            })
    };

    useEffect(() => {
        if (data) {
            setValue("first_name", data.first_name!)
            setValue("last_name", data.last_name!)
            setValue("customer_email", data.customer_email!)
            setValue("enable", data.enable!)

        }
    }, [data])

    return (
        <Dialog closable={false} draggable={false}
            visible={visible}
            className="custom-dialog p-0 m-0 w-23rem md:w-30rem dialog-width"
            showHeader={false}
            onHide={() => {
                setVisible(false)
                setData({})
                reset()
            }}
        >
            <div className="w-full text-v3 font-bold py-2 px-4" style={{marginTop:'2rem'}}>
                <div className="grid grid-nogutter add-branch">
                    <div className="col-10 hidden md:flex relative">
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-10 flex md:hidden relative" style={{ top: '4px' }}>
                        {title.toLocaleUpperCase()}
                    </div>
                    <div className="col-2 text-right">
                        <Badge value="X"
                            className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                            severity="danger"
                            onClick={() => {
                                setVisible(false)
                                setData({})
                                reset()
                            }}
                            style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                        ></Badge>
                    </div>
                </div>
            </div>
            <div className="py-2 px-4 w-full text-v2 font-bold">

                <form onSubmit={handleSubmit(onSubmit)} className="p-fluid ">
                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5"><p className="mb-2 mt-2 font-bold text-sm">First Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="first_name" control={control} rules={{
                                required: 'First Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field} autoFocus
                                    className={`${!!errors.first_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                        <div className="flex-initial align-items-center justify-content-center px-5">
                            <p className="mb-2 mt-2 font-bold text-sm">Last Name<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="last_name" control={control} rules={{
                                required: 'Last Name is required.',
                                pattern: {
                                    value: /^[0-9a-zA-Zก-๙-_/]+$/i, message: 'Invalid name. E.g. Example, ตัวอย่าง'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}
                                    className={`${!!errors.last_name ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>
                    </div>

                    <div className="flex">
                        <div className="flex-initial align-items-center justify-content-center pr-5">
                        <p className="mb-2 mt-2 font-bold text-sm">Email<span style={{ color: 'red' }}>*</span></p>
                            <Controller name="customer_email" control={control} rules={{
                                required: 'Email is required.',
                                pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address. E.g. example@g-able.com'
                                }
                            }} render={({ field }) => (
                                <InputText id={field.name} {...field}

                                    className={`${!!errors.customer_email ? 'p-invalid' : ''} input-border`}
                                />
                            )} /></div>

                    </div>
                    <p className="mb-2 mt-3">
                        <Controller name="enable" control={control}
                            render={({ field }) => (
                                <Checkbox inputId={field.name} onChange={(e) => field.onChange(e.checked)} checked={field.value}
                                    className={!!errors.enable ? 'p-invalid' : ''} />
                            )} />
                        <label htmlFor="accept" className={!!errors.enable ? 'p-invalid' : ''}> User account enable</label>
                    </p>

                    {(errors.first_name || errors.last_name || errors.customer_email ) &&
                        <div className="pl-2 mt-2">
                            <p className="p-error font-bold m-0">Hint</p>
                            <p className="m-0 p-error">{errors.first_name ? <small>{errors.first_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.last_name ? <small>{errors.last_name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.customer_email ? <small>{errors.customer_email.message}</small> : <></>}</p>
                        </div>
                    }

                    <Button
                        label="Submit"
                        type="submit"
                        className="p-button mt-3 transition-duration-300 hover:bg-indigo-700"
                        style={{ borderRadius: '30px', width: '194px', marginLeft: '43rem', background: '#292666' ,marginBottom:'2rem'}}

                    />  
                </form>
            </div>
        </Dialog>
    )
}

export default EditDirectoryDialog;