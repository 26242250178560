import { useCheckAdmin } from "../../utils/Properties";
import { useState, Fragment, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { ModelsShipType } from "../../api";
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { usePageWaiting, useToast } from "../../utils/Properties"
import CreateShipType from "../../components/Dialog/CreateShipType";
const ShipTypeManagement = () => {
    const properties = {
        title: "ShipType Management",
    };
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const setPageWaiting = usePageWaiting();
    const [shipTypes, setShipTypes] = useState([] as ModelsShipType[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const apiInstance = useSecureApiConnector()
    const [successToast, errorToast, ,] = useToast();
    const [selectedCity2, setSelectedCity2] = useState<any>(null);
    const [editFlag, setEditFlag] = useState<boolean>(false);

    const [dialogAdd, setDialogAdd] = useState(false)
    const [newShipType, setNewShipType] = useState({} as ModelsShipType);
    const [editShipType, setEditShipType] = useState({} as ModelsShipType);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const fetchData = () => {
        apiInstance.shiptype.getAll().then(res => res.data)
            .then(data => {
                setShipTypes(data.record_list)
                setTotalRecords(data.record_count!)

            })
    }

    const submitUpdate = (data: any) => {
        setPageWaiting(true)

        console.log(data);
        apiInstance.shiptype.update(data.name, editShipType.id!).then(res => res.data)
            .then(data => {
                refresh();
                setEditFlag(false)
                setPageWaiting(false)

            })
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch]);

    const refresh = () => {
        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const reject = () => {
        successToast("test")
        selectedCity2("")
    }

    const createShipType = (data: any) => {
        console.log(data);
        setPageWaiting(true)

        apiInstance.shiptype.create(data.name)
            .then(() => {
                successToast("Created")
                setDialogAdd(false)
                refresh()
                fetchData()
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast("Create failed")
                setDialogAdd(false)
                setPageWaiting(false)

            })
    }

    return (
        <>
            <CreateShipType data={newShipType} setData={setNewShipType} visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} onSubmit={createShipType} />
            <CreateShipType data={editShipType} setData={setEditShipType} visible={editFlag} setVisible={setEditFlag} refresh={refresh} onSubmit={submitUpdate} editTiltle="Edit ShipType" edit={true} />

            <Card className="card-pad" title="ShipType Management">

                <div className="w-full relative flex" style={{ height: "48px", marginBottom: "1rem" }}>
                    <Button
                        icon="mdi mdi-plus-circle-outline"
                        onClick={() => setDialogAdd(true)}
                        style={{ left: '0rem', top: '5px', width: "8rem" }}
                        className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                        aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;New</Button>
                </div>

                <DataTable
                    className="text-v2"
                    value={shipTypes}
                    loading={loading}
                    responsiveLayout="scroll"
                    paginator
                    first={lazyParams.first}
                    rows={lazyParams.rows}
                    totalRecords={totalRecords}
                    onPage={(e: any) => {
                        setLazyParams(e);
                    }}
                    paginatorTemplate={paginatorTemplate}
                    paginatorClassName="justify-content-end"
                >
                    <Column
                        header="Timestamp"
                        field="created_at"
                        style={{
                            // minWidth: "300px",
                            width: "10%",
                            height: "55px",
                        }}
                        body={(item: ModelsShipType) => (
                            <>{moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                        )}
                    ></Column>
                    <Column
                        header="ID"
                        field="id"
                        style={{
                            // minWidth: "300px",
                            width: "15%",
                            height: "55px",
                        }}
                    ></Column>
                    <Column
                        style={{
                            width: "15%",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                            height: "55px",
                        }}
                        header="Name"
                        field="name"

                    ></Column>
                    <Column
                        style={{
                            // minWidth: "150px",
                            width: "5%",
                            height: "55px",
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                        }}
                        header=""
                        body={(item: ModelsShipType) => (
                            <>
                                <Button icon="pi pi-pencil" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400"
                                    style={{ borderRadius: "24px" }} onClick={() => { setEditShipType(item); setEditFlag(true) }} />


                            </>
                        )}

                    ></Column>

                </DataTable>
            </Card>
        </>




    )
}
export default ShipTypeManagement;
