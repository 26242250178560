import { Badge } from "primereact/badge"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext"
import { Dispatch, FC, Fragment, SetStateAction, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { ModelTenantInputFormat, ModelTenantOutputFormat, ModelsDirectory, ModelsInputFormat } from "../../api"
import { useSecureApiConnector } from "../../utils/ApiConnector"
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Dropdown } from "primereact/dropdown"
import "./style.css"
import { useClipboard } from "../../utils/CopyToClipboard"
import { Tooltip } from "primereact/tooltip"
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { Card } from "antd"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import CustomDeleteDialog from "./CustomDeleteDialog"

interface props {
    visible: boolean
    setVisible: Dispatch<SetStateAction<boolean>>
    refresh: () => void
    data: any
    setData: Dispatch<SetStateAction<any>>
    onSubmit: (data: any) => void;
    titleName?: string;
    tenant_id?: string
    tabName?: string

}

const ViewRbacDialog: FC<props> = ({ data, visible, setData, setVisible, refresh, onSubmit, titleName, tenant_id, tabName }) => {
    const apiInstance = useSecureApiConnector()
    const title = "Add RBac InputFormat Template"
    const setPageWating = usePageWaiting()
    const [viewFormat, setViewFormat] = useState([] as any);
    const [dataDel, setDataDel] = useState({} as any);
    const [filter, setFilter] = useState("")
    const [dialogSearch, setSearchDialog] = useState(false)
    const [loading, setLoading] = useState(true);
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelTenantInputFormat)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [successToast, errorToast, ,] = useToast();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 5,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [triggerFetch, setTriggerFetch] = useState(false)


    const defaultValues = {
        modelsInputFormat: [] as ModelsInputFormat[],
    }
    const { control, formState: { errors }, handleSubmit, setValue, reset, watch } = useForm({ defaultValues });
    const refreshData = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }

    const fetchData = () => {
        setLoading(true)
        if (tabName === "output format") {
            apiInstance.tenantOutputFormat
                .getOffset(lazyParams.first, lazyParams.rows, tenant_id!.toString())
                .then((res) => res.data)
                .then((data) => {

                    setTotalRecords(data?.record_count!)
                    setViewFormat(data?.record_list)
                    setLoading(false);
                })
        } else {
            apiInstance.tenantInputFormat
                .getOffset(lazyParams.first, lazyParams.rows, tenant_id!.toString())
                .then((res) => res.data)
                .then((data) => {

                    setTotalRecords(data?.record_count!)
                    setViewFormat(data?.record_list)
                    setLoading(false);
                })
        }

    };

    useEffect(() => {
        if (visible) {
            fetchData()
        }
    }, [visible])

    useEffect(() => {
        fetchData();
    }, [triggerFetch]);

    useEffect(() => {
        refreshData();
    }, [lazyParams]);

    const onSubmitDel = (data: any) => {
        if (tabName === "output format") {
            apiInstance.tenantOutputFormat.deleteTenantOutputFormat(dataDel)
                .then(() => {
                    successToast("Output Format deleted")
                    setObjectDeleteState({})
                    setDataDel({})
                    setDeleteDialog(false)
                    refresh()
                })
                .catch(() => {
                    errorToast("Output Format delete failed")
                    setObjectDeleteState({})
                })
        } else {
            apiInstance.tenantInputFormat.deleteTenantInputFormat(dataDel)
                .then(() => {
                    successToast("Input Format deleted")
                    setObjectDeleteState({})
                    setDataDel({})
                    setDeleteDialog(false)
                    refresh()
                })
                .catch(() => {
                    errorToast("Input Format delete failed")
                    setObjectDeleteState({})
                })
        }

    }

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 25, value: 25 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };


    return (
        <>
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmitDel} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{dataDel!.input_format?.name}</b> &nbsp;template? </>} />

            <Dialog closable={false} draggable={false}
                visible={visible}
                className="custom-dialog p-0 m-0 w-23rem "
                showHeader={false}
                style={{ minWidth: '70rem', minHeight: "31rem" }}
                onHide={() => {
                    setVisible(false)
                    setDataDel({})
                    reset()
                }}
            >
                <div className="w-full text-v3 font-bold py-2 px-4" style={{ marginTop: '2rem' }}>
                    <div className="grid grid-nogutter add-branch">
                        <div className="col-10 hidden md:flex relative">
                            {titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-10  flex md:hidden relative" style={{ top: '4px' }}>
                            {titleName ? titleName.toLocaleUpperCase() : title.toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setVisible(false)
                                    reset()
                                }}
                                style={{ marginTop: '-1rem', marginBottom: '0.5rem', background: '#A098AE' }}

                            ></Badge>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "inline-block",
                        justifyContent: "left",
                        alignItems: "center",
                        height: "30vh",
                        width: "100%",
                    }}
                >
                    <Card className="card-pad" >
                        <div className='w-full relative' style={{ height: "48px", marginBottom: "1rem" }}>
                            {/* <Button
                            icon="mdi mdi-plus-circle-outline"
                            // onClick={(event: any) => menu.current.toggle(event)}
                            onClick={() => setDialogAdd(true)}
                            style={{ left: '0rem', top: '5px' }}
                            className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                            aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add input template</Button> */}
                            <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                                <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                    onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" disabled />
                                <i className="pi pi-search gable-text-dark-blue" />

                            </span>
                            <Button
                                icon="mdi mdi-magnify"
                                style={{ right: '1rem', top: '5px' }}
                                className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                onClick={() => { setSearchDialog(true) }}
                            />
                        </div>
                        {tabName === "output format" ?
                            <DataTable
                                value={viewFormat}
                                lazy
                                loading={loading}
                                paginator
                                responsiveLayout="scroll"
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                totalRecords={totalRecords}
                                onPage={(e: any) => {
                                    setLazyParams(e);
                                }}
                                paginatorTemplate={paginatorTemplate}
                                paginatorClassName="justify-content-end"
                                tableStyle={{ minWidth: "100%" }}
                            >
                                <Column
                                    field="tenant_id"
                                    header="ID"
                                    style={{ minWidth: "35%" }}
                                ></Column>
                                <Column
                                    field="tenant.name"
                                    header="Tenant Name"
                                    style={{ width: "25%" }}
                                ></Column>
                                <Column
                                    field="output_format.name"
                                    header="OutputFormat Name"
                                    style={{ width: '20%' }}
                                ></Column>
                                <Column
                                    header="Status"
                                    body={(item) => (<>
                                        <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.output_format.enable! ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                                    </>)}
                                    style={{ width: '10%' }}
                                ></Column>

                                <Column className='text-right'
                                    body={(item) => (

                                        <span className=" flex">

                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "5.5rem", marginRight: '1rem' }}
                                            >
                                                <Button label="Delete" className="p-button-outlined p-button-danger"
                                                    style={{ borderRadius: '1rem', width: '6rem', height: '2rem', marginTop: '0.5rem' }}
                                                    onClick={() => {
                                                        setDataDel(item)
                                                        setDeleteDialog(true)
                                                    }}
                                                />
                                            </div>

                                        </span>
                                    )}
                                ></Column>
                            </DataTable> :
                            <DataTable
                                value={viewFormat}
                                lazy
                                loading={loading}
                                paginator
                                responsiveLayout="scroll"
                                first={lazyParams.first}
                                rows={lazyParams.rows}
                                totalRecords={totalRecords}
                                onPage={(e: any) => {
                                    setLazyParams(e);
                                }}
                                paginatorTemplate={paginatorTemplate}
                                paginatorClassName="justify-content-end"
                                tableStyle={{ minWidth: "100%" }}
                            >
                                <Column
                                    field="tenant_id"
                                    header="ID"
                                    style={{ width: "35%" }}
                                ></Column>
                                <Column
                                    field="tenant.name"
                                    header="Tenant Name"
                                    style={{ width: "25%" }}
                                ></Column>
                                <Column
                                    field="input_format.name"
                                    header="InputFormat Name"
                                    style={{ width: '20%' }}
                                ></Column>
                                <Column
                                    header="Status"
                                    body={(item) => (<>
                                        <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.input_format.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                                    </>)}
                                    style={{ width: '10%' }}
                                ></Column>

                                <Column className='text-right'
                                    body={(item) => (

                                        <span className=" flex">

                                            <div
                                                className="align-items-center justify-content-center "
                                                style={{ width: "5.5rem", marginRight: '1rem' }}
                                            >
                                                <Button label="Delete" className="p-button-outlined p-button-danger"
                                                    style={{ borderRadius: '1rem', width: '6rem', height: '2rem', marginTop: '0.5rem' }}
                                                    onClick={() => {
                                                        setDataDel(item)
                                                        setDeleteDialog(true)
                                                    }}
                                                />
                                            </div>

                                        </span>
                                    )}
                                ></Column>
                            </DataTable>
                        }

                    </Card>
                </div>
            </Dialog>
        </>
    )
}

export default ViewRbacDialog;