import { useCheckAdmin } from "../../utils/Properties";
import { useState, Fragment, useEffect, useRef } from "react";
import { Divider } from 'primereact/divider';
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import { ModelsSupport, WebProperty } from "../../api";
import './style.css';
import { useSecureApiConnector } from "../../utils/ApiConnector";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { usePageWaiting, useToast } from "../../utils/Properties"
import { Calendar } from "primereact/calendar";
import { Tooltip } from "primereact/tooltip"
import SearchDialog from "../../components/SearchDialog";
import CreateSystemProp from "../../components/Dialog/CreateSystemProp";
import { Image } from 'primereact/image';
const SystemMangement = () => {
    const properties = {
        title: "System Management",
    };
    const [loading, setLoading] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false);
    const setPageWaiting = usePageWaiting();
    const [siteProperty, setSiteProperty] = useState([] as WebProperty[]);
    const [totalRecords, setTotalRecords] = useState(0);
    const apiInstance = useSecureApiConnector()
    const [visible, setVisible] = useState<boolean>(false);
    const [visible2, setVisible2] = useState<boolean>(false);
    const [successToast, errorToast, ,] = useToast();
    const [webprop, setWebprop, ,] = useState({} as WebProperty);
    const [webprop2, setWebprop2, ,] = useState({} as ModelsSupport);
    const [selectedCity2, setSelectedCity2] = useState<any>(null);
    const [visible1, setVisible1] = useState<boolean>(false);
    const [editFlag, setEditFlag] = useState<boolean>(false);

    const [noCharge, setNoCharge] = useState(false)
    const [noChargeUploadId, setNoChargeUploadId] = useState("");
    const [filter, setFilter] = useState("");
    const [initData, setInitData] = useState([] as WebProperty[]);
    const [dialogSearch, setSearchDialog] = useState(false);
    const [dialogAdd, setDialogAdd] = useState(false)
    const [createData, setCreateData] = useState({} as WebProperty);
    const [editData, setEditData] = useState({} as WebProperty);
    const [confirmDelete, setConfirmDelete] = useState(false)
    const [deleteData, setDeleteData] = useState({} as WebProperty);

    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });

    const fetchData = () => {

        apiInstance.webProperty.getOffset(lazyParams.first, lazyParams.rows).then(res => res.data)
            .then(data => {
                setTotalRecords(data.record_count!)
                setSiteProperty(data.record_list)
                setInitData(data.record_list)
                setLoading(false)

            })
    }
    const submitDelete = () => {
        setPageWaiting(true)
        apiInstance.webProperty.deleteSystemProperty(deleteData.id!).then(res => res.data)
            .then(data => {
                refresh();
                setPageWaiting(false)

            })
    }

    const submitUpdate = (data: any) => {

        setPageWaiting(true)
        apiInstance.webProperty.updateWebproperty({ name: data.name, value: data.value, id: editData.id, started_at: data.started_at, expired_at: data.expired_at }).then(res => res.data)
            .then(data => {
                refresh();
                setEditFlag(false)
                setPageWaiting(false)

            })
    }

    useEffect(() => {
        fetchData()
    }, [triggerFetch]);

    useEffect(() => {
        // setLoading(true);
        // let sfilter = filter;
        // if (moment(filter, "MMMM Do YYYY", true).isValid()) {
        //     sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        // }

        // apiInstance.support
        //     .getSearchTenant({
        //         search: sfilter,
        //         offset: lazyParams.first,
        //         limit: lazyParams.rows,
        //     })
        //     .then((res) => res.data)
        //     .then((data) => {
        //         setTotalRecords(data.record_count!);
        //         setMogData(data.record_list);
        //         setLoading(false);
        //     })
        //     .catch(() => {
        //         setMogData([]);
        //         setLoading(false);
        //     });
    }, [lazyParams]);

    const refresh = () => {
        setTriggerFetch((triggerFetch) => !triggerFetch);
    };

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };
    const reject = () => {
        successToast("test")
        selectedCity2("")
    }

    useEffect(() => {

        if (filter === " " && siteProperty !== initData) {
            setSiteProperty(initData);
            setTotalRecords(initData.length)
        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.webProperty
                .getSearchWebproperty({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setSiteProperty(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setSiteProperty([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    const createSystemProp = (data: any) => {
        setPageWaiting(true)

        apiInstance.webProperty.createWebProp(data)
            .then(() => {
                successToast("Created")
                setDialogAdd(false)
                refresh()
                setPageWaiting(false)
            })
            .catch(() => {
                errorToast("Create failed")
                setDialogAdd(false)
                setPageWaiting(false)

            })
    }

    interface RowData {
        value: string;
    }

    const CustomColumn = (data: any) => {

        const value = data.value;
        const shortenedValue = value.length > 150 ? value.substring(0, 150) + "..." : value;

        return <>{value.includes("data:image/") ? <Image src={value} alt="Image" width="180" preview /> : shortenedValue}</>;
    };

    return (
        <>
            <CreateSystemProp data={createData} setData={setCreateData} visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} onSubmit={createSystemProp} />
            <CreateSystemProp data={editData} setData={setEditData} visible={editFlag} setVisible={setEditFlag} refresh={refresh} onSubmit={submitUpdate} editTiltle="Edit System Property" edit={true} />

            <SearchDialog
                visible={dialogSearch}
                setVisible={setSearchDialog}
                filter={filter}
                setFilter={setFilter}
            />
            <div className="grid grid-nogutter">
                <div className="col">
                    <ConfirmDialog visible={confirmDelete} onHide={() => { setConfirmDelete(false) }} message="Are you sure you want to delete property?"
                        header="Confirmation" icon="pi pi-exclamation-triangle" accept={submitDelete} reject={reject} />

                    <Card >
                        <div className="mt-2 mb-4 font-bold text-v1">
                            {properties.title}
                        </div>

                        <div className="w-full relative flex" style={{ height: "48px", marginBottom: "1rem" }}>
                            <Button
                                icon="mdi mdi-plus-circle-outline"
                                onClick={() => setDialogAdd(true)}
                                style={{ left: '0rem', top: '5px' }}
                                className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                                aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Create Property</Button>
                            <span
                                className="absolute hidden md:flex p-input-icon-right"
                                style={{ right: "1rem", top: "5px" }}
                            >
                                <InputText
                                    className="w-25rem custom-search-box text-v3"
                                    value={filter}
                                    onChange={(e) => {
                                        setFilter(e.target.value);
                                    }}
                                    placeholder="  Keyword Search..."
                                />
                                <i className="pi pi-search gable-text-dark-blue" />
                            </span>
                            <Button
                                icon="mdi mdi-magnify"
                                style={{ right: "1rem", top: "5px" }}
                                className="absolute p-button-rounded p-button-secondary flex md:hidden "
                                onClick={() => {
                                    //   setSearchDialog(true);
                                }}
                            />
                        </div>

                        <DataTable
                            className="text-v2"
                            value={siteProperty}
                            lazy
                            loading={loading}
                            responsiveLayout="scroll"
                            paginator
                            first={lazyParams.first}
                            rows={lazyParams.rows}
                            totalRecords={totalRecords}
                            onPage={(e: any) => {
                                setLazyParams(e);
                            }}
                            paginatorTemplate={paginatorTemplate}
                            paginatorClassName="justify-content-end"
                        >
                            <Column
                                header="Timestamp"
                                field="created_at"
                                style={{
                                    // minWidth: "300px",
                                    width: "10%",
                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>{moment(item.created_at).format("YYYY-MM-DD, HH:mm:ss")}</>
                                )}
                            ></Column>
                            <Column
                                header="ID"
                                field="id"
                                style={{
                                    // minWidth: "300px",
                                    width: "15%",
                                    height: "55px",
                                }}
                            ></Column>
                            <Column
                                style={{
                                    width: "15%",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                    height: "55px",
                                }}
                                header="Name"
                                field="name"

                            ></Column>
                            <Column
                                style={{
                                    width: "15%",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                    height: "55px",
                                }}
                                header="Value"
                                field="value"
                                body={CustomColumn}
                            ></Column>

                            {/* <Column
                                style={{
                                    width: "10%",
                                    height: "55px",
                                }}
                                header="Level"
                                field="level"
                                body={(item: ModelsSupport) => (
                                    <>
                                        {visible1 && item.id === support2.id! ? (
                                            <>
                                                {item.type! === 'Platform' ? (
                                                    <Dropdown value={selectedCity2} options={cities} onChange={onPlatformChange} optionLabel="name" />
                                                ) : (
                                                    <Dropdown value={selectedCity2} options={cities2} onChange={onPlatformChange} optionLabel="name" />
                                                )}
                                            </>
                                        ) : (
                                            item.type! === 'Platform' ? (item.level === 0 ? "Critical" : item.level === 1 ? "High" : item.level === 2 ? "Medium" : "Low") : (item.level === 0 ? "High" : item.level === 1 ? "Medium" : "Low")
                                        )}
                                    </>
                                )}
                            ></Column> */}

                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "15%",
                                    height: "55px",
                                }}
                                header="Start"
                                field="started_at"
                                body={(item: WebProperty) => (
                                    <>
                                        {item.started_at ? moment(item.started_at).format("YYYY-MM-DD HH:mm:ss") : null}
                                    </>
                                )}

                            ></Column>
                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "15%",
                                    height: "55px",
                                }}
                                header="expired"
                                field="expired_at"
                                body={(item: WebProperty) => (
                                    <>
                                        {item.expired_at ? moment(item.expired_at).format("YYYY-MM-DD HH:mm:ss") : null}
                                    </>
                                )}


                            ></Column>
                            {/* <Column
                                alignHeader={"center"}
                                header="Status"
                                field="status"
                                style={{
                                    // minWidth: "150px",
                                    width: "10%",

                                    height: "55px",
                                }}
                                body={(item: ModelsSupport) => (
                                    <>
                                        {item.status === "Open" && (
                                            <div
                                                className="status-pending margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-clock"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Open</span>
                                            </div>
                                        )}

                                        {item.status === "Closed" && (
                                            <div
                                                className="status-success margin-center text-white px-3 py-1 status-border flex"
                                                style={{
                                                    minWidth: "135px",
                                                    width: "100px",
                                                    marginLeft: "2.5rem"

                                                }}
                                            >
                                                <div className="text-left">
                                                    <i
                                                        className="pi pi-check-circle"
                                                        style={{ fontSize: "0.75rem", marginRight: "0.3rem" }}
                                                    ></i>
                                                </div>
                                                <span className="margin-status">Closed</span>
                                            </div>
                                        )}
                                    </>
                                )}
                            /> */}

                            <Column
                                style={{
                                    // minWidth: "150px",
                                    width: "20%",
                                    height: "55px",
                                    whiteSpace: "pre-wrap",
                                    wordBreak: "break-all",
                                }}
                                header=""
                                body={(item: WebProperty) => (
                                    <>
                                        <Button icon="pi pi-pencil" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-orange-400" style={{ borderRadius: "24px" }} onClick={() => { setEditData(item); setEditFlag(true) }} />

                                        <Button icon="pi pi-times" className="p-button-rounded p-button-text transition-duration-300 cursor-pointer hover:bg-red-400" style={{ borderRadius: "24px" }} onClick={() => { setDeleteData(item); setConfirmDelete(true) }} />

                                    </>
                                )}

                            ></Column>

                        </DataTable>
                    </Card>
                </div>
            </div>
        </>




    )
}
export default SystemMangement;
