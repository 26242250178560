import { useContext, createContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useApiConnector, useSecureApiConnector } from "./ApiConnector";
import { Toast } from "primereact/toast";
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ModelsDirectory, ModelsRole, ModelsUser, ModelsUserAdminTenent } from "../api";
import { useCookies } from "react-cookie";

type ContextProps = {
    issue: ModelsUserAdminTenent
    directory: ModelsDirectory
    isAdmin: boolean
    setWaiting: React.Dispatch<React.SetStateAction<boolean>>
    setDirectory: React.Dispatch<React.SetStateAction<ModelsDirectory>>
    toast: [(msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void, (msg: string, timeout?: number) => void]
    isAuthorized: boolean
}

export const AppPropertiesContext = createContext<ContextProps | null>(null);

interface AppProperties {
    children: any
}

const AppPropertiesProvider: React.FC<AppProperties> = ({ children }) => {
    const [, , removeCookie] = useCookies(["api-token-admin"])
    const [issue, setIssue] = useState({} as ModelsUserAdminTenent)
    const [directory, setDirectory] = useState({} as ModelsDirectory)
    const [isAuthorized, setIsAuthorized] = useState(false)
    const navigate = useNavigate()
    const location = useLocation();
    const [pageWaiting, setPageWaiting] = useState(false)
    const apiInstance = useSecureApiConnector();
    const toast = useRef({} as any)
    const [isAdmin, setIsAdmin] = useState(false)

    const successToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'success', summary: 'Success', detail: msg });
    }

    const errorToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'error', summary: 'Error', detail: msg });
    }

    const warnToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'warn', summary: 'Warning', detail: msg });
    }

    const infoToast = (msg: string, timeout?: number) => {
        toast.current.show({ life: timeout ? timeout : 3000, severity: 'info', summary: 'Information', detail: msg });
    }

    const getCredential = () => {
        apiInstance.authentication.getCredentialAdminTenant()
            .then(res => res.data)
            .then(res => {
                setIssue(res)
                setIsAuthorized(true)
                if (location.pathname === "/sign-in") {
                    navigate("/dashboard")
                }

            })
            .catch(() => {
                removeCookie("api-token-admin")
                setIsAuthorized(false)
                navigate("/sign-in")
            })
    }

    // const checkAdmin = () => {
    //     setPageWaiting(true)
    //     apiInstance.roles.getUserRoles()
    //         .then(res => res.data)
    //         .then(data => {
    //             setIsAdmin(data.name === "administrator")
    //         })
    //         .catch(() => { })
    //         .finally(() => {
    //             setPageWaiting(false)
    //         })

    // }


    useEffect(() => {
        if (location.pathname !== "/reset-password") {
            getCredential()
            // checkAdmin()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])



    return (

        <AppPropertiesContext.Provider value={{
            isAuthorized: isAuthorized, setWaiting: setPageWaiting, issue: issue, toast: [
                successToast, errorToast, infoToast, warnToast,
            ], directory: directory, setDirectory: setDirectory, isAdmin: isAdmin
        }}>
            <Toast ref={toast} />
            {pageWaiting &&
                <div className="bg-gray-50 h-screen w-screen" style={{ position: 'fixed', zIndex: 10000, opacity: 0.8 }}>
                    <div className="flex align-items-center h-screen justify-content-center">
                        <span className="text-center">
                            <div>
                                <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="5" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                            <p className="text-2xl">Wait a minutes</p>
                        </span>
                    </div>


                </div>
            }
            {children}
        </AppPropertiesContext.Provider>
    );
}

export default AppPropertiesProvider;
export const useIssue = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.issue;
}

export const useDirectory = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return [appContext.directory, appContext.setDirectory] as [ModelsDirectory, React.Dispatch<React.SetStateAction<ModelsDirectory>>];
}

export const useToast = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.toast;
}

export const useIsAuthorized = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.isAuthorized;
}

export const usePageWaiting = (): React.Dispatch<React.SetStateAction<boolean>> => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.setWaiting;
}

export const useCheckAdmin = () => {
    const appContext = useContext(AppPropertiesContext) as ContextProps
    return appContext.isAdmin;

}