import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { Fragment, useEffect, useState } from "react";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { ModelInputFormatShipType, ModelsInputFormat, ModelsShipType, ModelsUserAdminTenent } from "../api";
import CustomDeleteDialog from "../components/Dialog/CustomDeleteDialog";
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useToast, usePageWaiting } from "../utils/Properties";
import AddTemplateDialog from "../components/Dialog/AddTemplateDialog";
import { useNavigate, useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown"
import { Tooltip } from "primereact/tooltip";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { Divider } from "primereact/divider";
import moment from "moment";
import { InputSwitch } from 'primereact/inputswitch';

const InputFormatTemplate = () => {
    const apiInstance = useSecureApiConnector();
    const [data, setData] = useState([] as ModelsInputFormat[]);
    const [createTmp, setcreateTmp] = useState([] as ModelsInputFormat[]);
    const [initData, setInitData] = useState([] as ModelsInputFormat[]);
    const [dialogEdit, setDialogEdit] = useState(false)
    const [loading, setLoading] = useState(true);
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [objectDeleteState, setObjectDeleteState] = useState({} as ModelsUserAdminTenent)
    const [objectDeleteTemplte, setObjectDeleteTemplte] = useState({} as ModelsInputFormat)
    const [dialogDelete, setDeleteDialog] = useState(false)
    const [confirmBind, setConfirmBind] = useState(false)
    const [confirmEnable, setConfirmEnable] = useState(false)
    const [confirmDeleteBind, setConfirmDeleteBind] = useState(false)
    const [dialogAdd, setDialogAdd] = useState(false)
    const [filter, setFilter] = useState("")
    const [dialogSearch, setSearchDialog] = useState(false)
    const [successToast, errorToast, ,] = useToast();
    const location = useLocation();
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);
    const [objectState, setObjectState] = useState({} as ModelsInputFormat)
    const [hoveredItem, setHoveredItem] = useState(null);
    const [displayBinding, setDisplayBinding] = useState(false);
    const [selectedShiptype, setSelectedShiptype] = useState([] as ModelsShipType[]);
    const [listInputFormatShipType, setListInputFormatShipType] = useState([] as ModelInputFormatShipType[]);
    const [shipTypes, setShipTypes] = useState([] as ModelsShipType[]);
    const [deleteInputFormatShipType, setDeleteInputFormatShipType] = useState({} as ModelsShipType);
    const [checked1, setChecked1] = useState(false);
    const [updateEnable, setUpdateEnable] = useState({} as ModelsInputFormat)
    const setPageWaiting = usePageWaiting()
    const handleHover = (item: any) => {
        setHoveredItem(item);
    };

    const handleMouseLeave = () => {
        setHoveredItem(null);
    };

    const fetchData = () => {
        setLoading(true);

        apiInstance.inputFormat.getOffset(lazyParams.first, lazyParams.rows)
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!)
                setData(data.record_list);
                setInitData(data.record_list)
                setTriggerFetch(true);
                setLoading(false);
            })
    };

    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }

    const onSubmit = async (data: any) => {

        await apiInstance.inputFormat.deleteTemplate(objectDeleteTemplte.id!.toString())
            .then(() => {
                successToast("template deleted")
                setObjectDeleteState({})
                setDeleteDialog(false)
            })
            .catch(() => {
                errorToast("template delete failed")
                setObjectDeleteState({})
            })

        refresh()

    }

    const inputFormatShipType = () => {
        apiInstance.InputTemplateShipType.getAllByInputFormat(objectState.id!)
            .then((res) => res.data)
            .then((data) => {
                setListInputFormatShipType(data.record_list);
            })
    }

    const getAllShipType = () => {
        apiInstance.facilities.getAllShipTypeWithOutSelect(objectState.id!).then((data) => data.data)
            .then((data) => {
                setShipTypes(data.record_list)
            })
    }

    const onSubmitBinding = async (data: any) => {
        setConfirmBind(false)
        if (selectedShiptype.length > 0) {
            await apiInstance.InputTemplateShipType.create(objectState.id!, selectedShiptype)
                .catch(() => {
                    errorToast("binding ship type failed")
                })
            successToast("binding success")
            setSelectedShiptype([])

        }
        showBindingShipType()

    }

    const onSubmitEnableOrDisable = async (data: any) => {
        setPageWaiting(true)
        updateEnable.enable = checked1
        setConfirmEnable(false)
        await apiInstance.inputFormat.updateEnable(updateEnable)
            .then(() => { successToast(`${checked1 ? "enable" : "disable"} success`); fetchData() })
            .catch(() => { errorToast(`${checked1 ? "enable" : "disable"} failed`); setPageWaiting(false) })
        setPageWaiting(false)
    }

    const onDeleteBinding = async (data: any) => {
        setConfirmDeleteBind(false)
        await apiInstance.InputTemplateShipType.deleteShipTypeBinding(objectState.id!, deleteInputFormatShipType.id!)
            .catch(() => {
                errorToast("delete binding ship type failed")
            })
        successToast("delete binding success")
        showBindingShipType()

    }

    const createTemplate = (data: any) => {
        
        apiInstance.inputFormat.createTemplate({ name: data.name })
            .then(() => {
                successToast("template added")
                setDialogAdd(false)
                refresh()
            })
            .catch(() => {
                errorToast("add template failed")
                setDialogAdd(false)
            })
    }

    const showBindingShipType = () => {
        inputFormatShipType()
        getAllShipType()
    }

    const EditTemplate = (data: any) => {
        apiInstance.inputFormat.editTemplate({
            id: objectState.id,
            name: data.name,
            created_at: objectState.created_at

        })
            .then(() => {
                successToast("template edited")
                setDialogEdit(false)
                refresh()
            })
            .catch(() => {
                errorToast("edit template failed")
                setDialogAdd(false)
            })
    }

    useEffect(() => {
        fetchData();
    }, [triggerFetch]);

    useEffect(() => {
        setLoading(true);
        let sfilter = filter;
        if (moment(filter, "MMMM Do YYYY", true).isValid()) {
            sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
        }

        apiInstance.inputFormat
            .getSearch({
                search: sfilter,
                offset: lazyParams.first,
                limit: lazyParams.rows,
            })
            .then((res) => res.data)
            .then((data) => {
                setTotalRecords(data.record_count!);
                setData(data.record_list);
                setLoading(false);
            })
            .catch(() => {
                setData([]);
                setLoading(false);
            });
    }, [lazyParams]);

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Close" icon="pi pi-check" onClick={clearData} autoFocus />
            </div>
        );
    }

    const clearData = () => {
        setDisplayBinding(false)
        setSelectedShiptype([])
    }

    useEffect(() => {

        if (filter === " " && data !== initData) {
            setData(initData);
            setTotalRecords(initData.length)

        } else {
            setLoading(true);
            let sfilter = filter;
            if (moment(filter, "MMMM Do YYYY", true).isValid()) {
                sfilter = moment(new Date(filter)).format("YYYY-MM-DD");
            }

            apiInstance.inputFormat
                .getSearch({
                    search: sfilter,
                    offset: lazyParams.first,
                    limit: lazyParams.rows,
                })
                .then((res) => res.data)
                .then((data) => {
                    setTotalRecords(data.record_count!);
                    setData(data.record_list);
                    setLoading(false);
                })
                .catch(() => {
                    setData([]);
                    setLoading(false);
                });
        }
    }, [filter]);

    return (
        <>
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={dialogDelete} setVisible={setDeleteDialog} refresh={refresh}
                onSubmit={onSubmit} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{objectDeleteTemplte.name}</b> &nbsp;template? </>} />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={confirmBind} setVisible={setConfirmBind} refresh={refresh}
                onSubmit={onSubmitBinding} meessage={<>Confirm to Binding &nbsp;template with ship type? </>} customTitle={"BINDING CONFIRMATION"} />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={confirmDeleteBind} setVisible={setConfirmDeleteBind} refresh={refresh}
                onSubmit={onDeleteBinding} meessage={<>Confirm to delete &nbsp;<b className='font-bold'>{deleteInputFormatShipType!.name!}</b> &nbsp;? </>} />
            <CustomDeleteDialog data={objectDeleteState} setData={setObjectDeleteState} visible={confirmEnable} setVisible={setConfirmEnable} refresh={refresh}
                onSubmit={onSubmitEnableOrDisable} meessage={<>Confirm to {checked1 ? "enable" : "disable"} this&nbsp;template? </>} customTitle={`${checked1 ? "Enable" : "Disable"} CONFIRMATION`} />
            <AddTemplateDialog data={createTmp} setData={setcreateTmp} visible={dialogAdd} setVisible={setDialogAdd} refresh={refresh} onSubmit={createTemplate} />
            <AddTemplateDialog data={objectState} setData={setObjectState} visible={dialogEdit} setVisible={setDialogEdit} refresh={refresh} onSubmit={EditTemplate} edit={true} editTiltle="Edit Template" />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                <Card className="card-pad" title="InputFormat Management">
                    <div className='w-full relative' style={{ height: "48px", marginBottom: "1rem" }}>
                        <Button
                            icon="mdi mdi-plus-circle-outline"
                            onClick={() => setDialogAdd(true)}
                            style={{ left: '0rem', top: '5px' }}
                            className="absolute p-button-rounded background-dark-blue text-v3 transition-duration-200 cursor-pointer hover:bg-indigo-700"
                            aria-controls="popup_menu" aria-haspopup >&nbsp;&nbsp;Add input template</Button>
                        <span className='absolute hidden md:flex p-input-icon-right' style={{ right: '1rem', top: '5px' }}>
                            <InputText className="w-full text-v3 custom-search-box gable-text-dark-blue" value={filter}
                                onChange={(e) => { setFilter(e.target.value) }} placeholder="Keyword Search" />
                            <i className="pi pi-search gable-text-dark-blue" />

                        </span>
                        <Button
                            icon="mdi mdi-magnify"
                            style={{ right: '1rem', top: '5px' }}
                            className="absolute p-button-rounded p-button-secondary flex md:hidden "
                            onClick={() => { setSearchDialog(true) }}
                        />
                    </div>
                    <DataTable
                        value={data}
                        lazy
                        loading={loading}
                        paginator
                        responsiveLayout="scroll"
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={(e: any) => {
                            setLazyParams(e);
                        }}
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="justify-content-end"
                        tableStyle={{ minWidth: "100%" }}
                    >
                        <Column
                            field="id"
                            header="ID"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column
                            field="name"
                            header="Name"
                            style={{ width: "35%" }}
                        ></Column>
                        <Column header="Status" body={(item: ModelsUserAdminTenent) => (<>
                            <i className={`mdi mdi-checkbox-blank-circle text-3xl ${item.enable ? "text-green-500" : "text-gray-500"}`} style={{ paddingLeft: '0.8rem' }}></i>
                        </>)}></Column>
                        <Column className='text-right' body={(item) => (
                            <span className="p-buttonset flex">
                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <InputSwitch checked={item.enable} onChange={(e) => { setChecked1(e.value); setUpdateEnable(item); setConfirmEnable(true) }} />
                                </div>

                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <Tooltip target=".tooltip-target" position="left" />
                                    <img
                                        className='cursor-pointer tooltip-target'
                                        src={require("../assets/plus-solid.png")}
                                        alt="plus"
                                        data-pr-tooltip="binding input format and shiptype"
                                        style={{
                                            borderRadius: '20px',
                                            width: '20px',
                                            transition: 'transform 0.2s',
                                            transform: hoveredItem === item ? 'scale(1.2)' : 'scale(1)',
                                        }}

                                        onMouseOver={() => handleHover(item)}
                                        onMouseLeave={handleMouseLeave}
                                        onClick={() => {
                                            setObjectState(item)
                                            setDisplayBinding(true)
                                            // inputFormatShipType()
                                            // getAllShipType()
                                        }}
                                    />
                                </div>

                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        className='transition-duration-200 hover:bg-indigo-700 cursor-pointer'
                                        src={require("../assets/Group 36.png")}
                                        alt="pencil"
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        onClick={() => {
                                            setObjectState(item)
                                            setDialogEdit(true)
                                        }}
                                    />
                                </div>

                                <div
                                    className="align-items-center justify-content-center "
                                    style={{ width: "3.5rem" }}
                                >
                                    <img
                                        className={'transition-duration-200 cursor-pointer  hover:bg-red-500'}
                                        src={require("../assets/Group 32.png")}
                                        style={{ borderRadius: '20px', width: '27px' }}
                                        alt="bin"
                                        onClick={() => {
                                            setObjectDeleteTemplte(item)
                                            setDeleteDialog(true)
                                        }}
                                    />
                                </div>
                            </span>

                        )}></Column>
                    </DataTable>
                </Card>
            </div>

            <Dialog header="Binding Shiptype" visible={displayBinding} style={{ width: '50rem' }} footer={renderFooter} onShow={showBindingShipType} onHide={clearData}>
                <div className="flex">
                    <MultiSelect value={selectedShiptype} options={shipTypes} onChange={(e) => setSelectedShiptype(e.value)} optionLabel="name"
                        placeholder="Select Ship type" filter className="multiselect-custom" />
                    <Button onClick={() => setConfirmBind(true)} style={{ marginLeft: "1rem" }}>Add </Button>
                </div>

                <Divider />
                <DataTable
                    value={listInputFormatShipType}
                    lazy
                    loading={loading}
                    // paginator
                    responsiveLayout="scroll"
                    // first={lazyParams.first}
                    // rows={lazyParams.rows}
                    // totalRecords={totalRecords}
                    // onPage={(e: any) => {
                    //     setLazyParams(e);
                    // }}
                    // paginatorTemplate={paginatorTemplate}
                    paginatorClassName="justify-content-end"
                    tableStyle={{ minWidth: "100%" }}
                >
                    <Column
                        field="created_at"
                        header="Create At"
                        style={{ width: "35%" }}
                    ></Column>
                    <Column
                        field="input_format.name"
                        header="Input Format Name"
                        style={{ width: "35%" }}
                    ></Column>
                    <Column
                        field="ship_type.name"
                        header="Ship Type Name"
                        style={{ width: "35%" }}
                    ></Column>
                    <Column className='text-right' body={(item) => (
                        <span className="p-buttonset flex">

                            <div
                                className="align-items-center justify-content-center "
                                style={{ width: "3.5rem" }}
                            >
                                <img
                                    className={'transition-duration-200 cursor-pointer  hover:bg-red-500'}
                                    src={require("../assets/Group 32.png")}
                                    style={{ borderRadius: '20px', width: '27px' }}
                                    alt="bin"
                                    onClick={() => {
                                        setDeleteInputFormatShipType(item.ship_type)
                                        setConfirmDeleteBind(true)
                                    }}
                                />
                            </div>
                        </span>

                    )}></Column>
                </DataTable>
            </Dialog>
        </>
    );
};
export default InputFormatTemplate;
