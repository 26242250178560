
import React, { useEffect, useState } from 'react';
import { DataTable, DataTableRowEditCompleteParams } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
// import { InputNumber, InputNumberValueChangeEvent } from 'primereact/inputnumber';
// import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { Tag } from 'primereact/tag';
import "../global.css"
import "./style.css"
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primereact/resources/primereact.css';                       // core css
import 'primeicons/primeicons.css';
import { Card } from 'primereact/card';
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
// import { InputTextarea } from 'primereact/inputtextarea';
// import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { Tenant, tn } from '../api';
import { useSecureApiConnector } from '../utils/ApiConnector';
// import { ModelsDirectory, ModelsUser } from '../api';
import { Badge } from "primereact/badge"
// import { useClipboard } from "../utils/CopyToClipboard"
import { Tooltip } from "primereact/tooltip"
import { useToast } from "../utils/Properties"
import { Controller, useForm } from 'react-hook-form';
import "./style.css"
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Calendar } from "primereact/calendar";
import moment from 'moment';
import { Dropdown } from 'primereact/dropdown';
import { Fragment } from "react";

interface Product {
    id?: string;
    code?: string;
    name?: string;
    description?: string;
    image?: string;
    price?: number;
    category?: string;
    quantity?: number;
    inventoryStatus?: string;
    rating?: number;
}

function TenantManagement() {
    let emptyTenent: tn = {
        id: undefined,
        created_at: "",
        updated_at: "",
        deleted_at: "",
        name: "",
        customer_name: "",
        customer_address: "",
        customer_telnumber: "",
        customer_email: "",
        // Directories: []
    }
    let emptyProduct = {
        id: '',
        name: '',
        image: '',
        description: '',
        category: '',
        price: 0,
        quantity: 0,
        rating: 0,
        inventoryStatus: 'INSTOCK'
    };
    const [products, setProducts] = useState([] as Product[]);
    // const [statuses] = useState<string[]>(['INSTOCK', 'LOWSTOCK', 'OUTOFSTOCK']);
    const [loading, setLoading] = useState(false)
    const [product, setProduct] = useState(emptyProduct);
    const [tenentDialog, setTenentDialog] = useState(false);
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [noCharge, setNoCharge] = useState(false)
    const [pickPeriodDate, setPickPeriodDate] = useState(false)
    const [noChargeTenent, setNoChargeTenent] = useState(emptyTenent);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
    });
    const [totalRecords, setTotalRecords] = useState(0);

    const [tenent, setTenent] = useState(emptyTenent);
    const [tenents, setTenents] = useState([]);
    const [selectedTenents, setSelectedTenents] = useState([] as tn[]);
    const apiInstance = useSecureApiConnector()
    const [tempPasswd, setTempPasswd] = useState('')
    const [tempPasswdDialog, setTempPasswdDialog] = useState(false)
    // const clipboard = useClipboard()
    const [successToast, errorToast, ,] = useToast();
    const defaultValues = {
        name: '',
        customer_name: '',
        customer_address: '',
        customer_telnumber: '',
        customer_email: '',
        directory: null,
    }
    const pattern = /^(0\d{8}|0\d{9})$/;
    const { control, formState: { errors }, handleSubmit, setValue, reset } = useForm({ defaultValues });
    const [visible, setVisible] = useState(false)

    const [dateRange, setDateRange] = useState<Date[]>([
        moment().clone().startOf("month").toDate(),
        new Date(),
    ]);

    const copy = (msg: string) => {
        // clipboard(msg)
        //     .then(() => {
        //         successToast(`Copy ${msg} to clipboard`)
        //     })
        //     .catch(() => {
        //         errorToast(`Copy ${msg} to clipboard failed`)
        //     })
    }


    const openNew = () => {
        setProduct(emptyProduct);
        setTenent(emptyTenent)
        setTenentDialog(true);
    };

    const deleteTenents = () => {
        setLoading(true)
        apiInstance.tenants.deleteTenents(selectedTenents).then(res => {
            setSelectedTenents([])
            setTriggerFetch(true)
            setLoading(false)
        })

    }

    const hideDialog = () => {
        setTenentDialog(false);
    };

    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: any) => {
        const val = (e.target && e.target.value) || '';
        let _product = { ...product };
        _product.name = val;

        // setTenent(_tenent)
        setProduct(_product);
    };

    const saveProduct = (data: any) => {
        setLoading(true)
        let tn: Tenant = {
            Customer_Address: data.customer_address,
            Customer_Email: data.customer_email,
            Customer_Name: data.customer_name,
            Customer_Telnumber: data.customer_telnumber,
            Name: data.name
        }

        apiInstance.tenants.addTenents(tn).then(res => res.data)
            .then(resT => {
                setTriggerFetch(true)
                setLoading(false)
            })

        setTenentDialog(false);
    };

    const tenentDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" onClick={hideDialog} />
            <Button label="Create" icon="pi pi-check" type="submit" />
        </React.Fragment>
    );

    const fetchData = () => {

        apiInstance.tenants.getOffset(lazyParams.first, lazyParams.rows)
            .then(res => res.data)
            .then(data => {
                setTenent(data.record_list)
                setTenents(data.record_list)
                setTotalRecords(data.record_count!)
                setTriggerFetch(false)
                setLoading(false)
            })

    }

    useEffect(() => {
        setLoading(true)
        fetchData()

    }, [triggerFetch]);

    useEffect(() => {
        fetchData();
    }, [triggerFetch]);

    const refresh = () => {
        setTriggerFetch(triggerFetch => !triggerFetch);
    }

    useEffect(() => {
        refresh();
    }, [lazyParams]);

    const paginatorTemplate = {
        layout: "RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink",
        RowsPerPageDropdown: (options: any) => {
            const dropdownOptions = [
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 50, value: 50 },
                { label: 100, value: 100 },
            ];

            return (
                <Fragment>
                    <span
                        className="mx-1"
                        style={{ color: "var(--text-color)", userSelect: "none" }}
                    >
                        Items per page:{" "}
                    </span>
                    <Dropdown
                        value={options.value}
                        options={dropdownOptions}
                        onChange={options.onChange}
                    />
                </Fragment>
            );
        },
        CurrentPageReport: (options: any) => {
            return (
                <span
                    style={{
                        color: "var(--text-color)",
                        userSelect: "none",
                        width: "120px",
                        textAlign: "center",
                    }}
                >
                    {options.first} - {options.last} of {options.totalRecords}
                </span>
            );
        },
    };

    const onRowEditComplete = (e: DataTableRowEditCompleteParams) => {
        let _products = [...products!];
        let { newData, index } = e;

        _products[index] = newData;

        setProducts(_products);
    };

    const textEditor = (options: any) => {
        return <InputText type="text" value={options.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => options.editorCallback(e.target.value)} />;
    };

    const onSubmit = (data: any) => {
        saveProduct(data)
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="New" icon="pi pi-plus" onClick={openNew} />
                <Button label="Delete" icon="pi pi-trash" onClick={deleteTenents} disabled={!selectedTenents || !selectedTenents.length} />
            </div>
        );
    };

    const reject = () => {
        setPickPeriodDate(false);
        setDateRange([
            moment().clone().startOf("month").toDate(),
            new Date(),
        ])
        setNoCharge(false)
    }

    const submit = () => {
        setPickPeriodDate(false);
        setDateRange([
            moment().clone().startOf("month").toDate(),
            new Date(),
        ])
        apiInstance.facilities.clearFreightTransaction(noChargeTenent.id!, moment(dateRange[0]).format("yyyy-MM-DD"), moment(dateRange[1]).format("yyyy-MM-DD"))
        setNoCharge(false)
        successToast("Clear transaction succese")
    }

    return (
        <>
            <ConfirmDialog visible={noCharge} onHide={() => setNoCharge(false)} message="Are you sure you want to clear transaction?"
                header="Confirmation" icon="pi pi-exclamation-triangle" accept={submit} reject={reject} />
            <div style={{ display: 'inline-block', justifyContent: 'left', alignItems: 'center', height: '30vh', width: "100%" }}>
                <Card className='card-pad' title="Tenent Management">
                    <Toolbar className="mb-4" left={leftToolbarTemplate} ></Toolbar>
                    <DataTable
                        showGridlines
                        value={tenents}
                        lazy
                        loading={loading}
                        responsiveLayout="scroll"
                        selectionMode={"multiple"}
                        selection={selectedTenents}
                        paginator
                        first={lazyParams.first}
                        rows={lazyParams.rows}
                        totalRecords={totalRecords}
                        onPage={(e: any) => {
                            setLazyParams(e);
                        }}
                        onSelectionChange={e => {
                            setSelectedTenents(e.value)
                        }}
                        editMode="row"
                        dataKey="name"
                        onRowEditComplete={onRowEditComplete}
                        tableStyle={{ minWidth: '50rem' }}
                        paginatorTemplate={paginatorTemplate}
                        paginatorClassName="justify-content-end"
                    >

                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                        <Column field="name" header="Name" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="customer_name" header="Customer Name" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="customer_address" header="Customer Address" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="customer_telnumber" header="Customer Telnumber" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="customer_email" header="Customer Email" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                        <Column field="noCharge" body={(item: any) => (<>
                            <Tooltip target=".tooltip-target" position="left" />
                            <Button icon="pi pi-dollar" className='cursor-pointer tooltip-target' data-pr-tooltip="No Charge" onClick={() => {
                                setPickPeriodDate(true); setNoChargeTenent(item);
                            }} />
                        </>)} style={{ width: '20%' }}></Column>
                        {/* <Column field="price" header="Price" body={priceBodyTemplate} editor={(options) => priceEditor(options)} style={{ width: '20%' }}></Column> */}
                        {/* <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column> */}
                    </DataTable>
                </Card>

            </div>

            <Dialog visible={tenentDialog} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header="Tenent Details" modal className="p-fluid" onHide={hideDialog}>
                <form onSubmit={handleSubmit(onSubmit)} className="p-d-flex p-flex-column p-fluid">
                    <div className="field">
                        <label htmlFor="name" className="font-bold">
                            Name
                        </label>

                        <Controller name="name" control={control}
                            rules={{
                                required: "Name is required"
                            }}
                            render={({ field, fieldState }) => (
                                <div>
                                    <InputText
                                        id={field.name} {...field}
                                        className={!!errors.name ? 'p-invalid' : ''}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="customer_name" className="font-bold">
                            Customer Name
                        </label>
                        <Controller name="customer_name" control={control}
                            rules={{
                                required: "Customer Name is required"
                            }}
                            render={({ field, fieldState }) => (
                                <div>
                                    <InputText
                                        id={field.name} {...field}
                                        className={!!errors.customer_name ? 'p-invalid' : ''}
                                    />
                                </div>
                            )}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="customer_address" className="font-bold">
                            Customer Address
                        </label>
                        <Controller name="customer_address" control={control}
                            rules={{
                                required: "Address is required"
                            }}
                            render={({ field, fieldState }) => (
                                <div>
                                    <InputText
                                        id={field.name} {...field}
                                        className={!!errors.customer_address ? 'p-invalid' : ''}
                                    />
                                </div>
                            )}
                        />

                    </div>
                    <div className="field">
                        <label htmlFor="customer_telnumber" className="font-bold">
                            Customer Tel.
                        </label>
                        <Controller name="customer_telnumber" control={control}
                            rules={{
                                required: 'Telnumber is required.', pattern: {
                                    value: pattern,
                                    message: 'Invalid Telnumber. E.g. 027819333 or 0621983565'
                                }
                            }}
                            render={({ field, fieldState }) => (
                                <div >
                                    <InputText
                                        keyfilter="int"
                                        placeholder="Ex. 027819333 or 0621983565"
                                        id={field.name} {...field}
                                        className={!!errors.customer_telnumber ? 'p-invalid' : ''}
                                    />
                                </div>
                            )}
                        />
                        {/* <InputText
                            keyfilter="int"
                            placeholder="Ex. 027819333"
                            id="customer_telnumber"
                            value={tenent.customer_telnumber}
                            onChange={(e) => onInputChange(e, 'customer_telnumber')}
                            pattern="[0-9]{10}"
                            required /> */}
                    </div>
                    <div className="field">
                        <label htmlFor="customer_email" className="font-bold">
                            Customer Email
                        </label>
                        <Controller name="customer_email" control={control}
                            rules={{
                                required: 'Email is required.', pattern: {
                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                    message: 'Invalid email address. E.g. example@g-able.com'
                                }
                            }}
                            render={({ field, fieldState }) => (

                                <div >
                                    <InputText
                                        id={field.name} {...field}
                                        className={!!errors.customer_email ? 'p-invalid' : ''}
                                        placeholder="Email"
                                    />
                                </div>
                            )} />
                    </div>

                    {/* <div className='flex justify-content-end'>
                        <div className='flex-initial'>
                            <Button label="Cancel" icon="pi pi-times" onClick={hideDialog} />
                        </div>
                        <div className='flex-initial ml-2'>
                            <Button label="Create" icon="pi pi-check" type="submit" />
                        </div>
                    </div> */}

                    {(errors.customer_email || errors.name || errors.customer_telnumber || errors.customer_address || errors.customer_name) &&
                        <div className="pl-2">
                            <p className="p-error font-bold">Hint</p>
                            <p className="m-0 p-error">{errors.customer_email ? <small>{errors.customer_email.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.name ? <small>{errors.name.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.customer_telnumber ? <small>{errors.customer_telnumber.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.customer_address ? <small>{errors.customer_address.message}</small> : <></>}</p>
                            <p className="m-0 p-error">{errors.customer_name ? <small>{errors.customer_name.message}</small> : <></>}</p>
                        </div>
                    }

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '8%' }}>
                        <Button label="Cancel" icon="pi pi-times" onClick={hideDialog} style={{ marginRight: '8px' }} />
                        <Button label="Create" icon="pi pi-check" type="submit" />
                    </div>
                </form>



            </Dialog>

            <Dialog closable={false} draggable={false}
                visible={pickPeriodDate}
                header="&nbsp;&nbsp;&nbsp;No Charge Period Date"
                modal
                className=" p-fluid"
                footer={
                    <>
                        <Button label="No" icon="pi pi-times" onClick={() => {
                            setPickPeriodDate(false); setDateRange([
                                moment().clone().startOf("month").toDate(),
                                new Date(),
                            ])
                        }} className="p-button-text" />
                        <Button label="Yes" icon="pi pi-check" onClick={() => setNoCharge(true)} autoFocus />
                    </>
                }
                onHide={() => {
                    setPickPeriodDate(false);
                    setDateRange([
                        moment().clone().startOf("month").toDate(),
                        new Date(),
                    ])
                }}
            >
                <div className="field">
                    <Calendar
                        id="range"
                        style={{ width: "250px" }}
                        className="ml-3"
                        value={dateRange}
                        onChange={(e) => setDateRange(e.value as Date[])}
                        selectionMode="range"
                        readOnlyInput
                    />
                </div>

            </Dialog>

            <Dialog closable={false} draggable={false}
                visible={tempPasswdDialog}
                className="custom-dialog p-0 m-0 w-23rem md:w-30rem"
                showHeader={false}
                onHide={() => {
                    setTempPasswd('')
                    setTempPasswdDialog(false)
                }}
            >
                <div className="w-full gable-bg-gray py-2 px-4">
                    <div className="grid grid-nogutter">
                        <div className="col-10 text-xl hidden md:flex text-white relative">
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-10 text-sm flex md:hidden text-white relative" style={{ top: '4px' }}>
                            {"temporary password".toLocaleUpperCase()}
                        </div>
                        <div className="col-2 text-right">
                            <Badge value="X"
                                className="transition-duration-300 cursor-pointer hover:bg-red-400 mt-1"
                                severity="danger"
                                onClick={() => {
                                    setTempPasswd('')
                                    setTempPasswdDialog(false)
                                }}
                            ></Badge>
                        </div>
                    </div>
                </div>
                <div className="p-5 text-center">
                    <span className="p-3 text-4xl border-1 border-blue-50 border-round-2xl gable-text-gray bg-blue-50">{tempPasswd}</span>

                    <Tooltip target=".copy-btn" />
                    <span
                        onClick={() => {
                            copy(tempPasswd)
                        }}
                        className='ml-2 copy-btn text-lg border-circle gable-text-orange hover:bg-orange-400 hover:text-white transition-duration-300 cursor-pointer border-1 px-1'
                        data-pr-tooltip="Copy to clipboard">
                        <i className="pi pi-fw pi-paperclip"></i>
                    </span>
                </div>
            </Dialog>

        </>

    );
}
export default TenantManagement;
