
export const useFileWithType = () => {
    const doSaveAs = (fileName: string, arrayBuffer: ArrayBuffer, type: string) => {
        var blob = new Blob([arrayBuffer], { type: type });
        const objectUrl: string = URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

        a.href = objectUrl;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(objectUrl);

    }
    return doSaveAs

}