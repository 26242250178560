import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import { useSecureApiConnector } from "../utils/ApiConnector";
import { ModelsUserAdminTenent } from "../api";
import { useLocation } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { TabMenu } from 'primereact/tabmenu';
import InputFormatTemplate from "./InputFormatTemplate";
import OutputFormatTemplate from "./OutputFormatTemplate";
import InputFormatRBAC from "./InputFormatRBAC";
import OutputFormatRBAC from "./OutputFormatRBAC";



const ManageRBAC = () => {
    const apiInstance = useSecureApiConnector();
    const [data, setData] = useState([] as ModelsUserAdminTenent[]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const [triggerFetch, setTriggerFetch] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);

    const items = [

        {
            label: "InputFormat",
            icon: "mdi mdi-cash-multiple",
            // url: "/PriceManagement",
        },
        {
            label: "OutputFormat",
            icon: "mdi mdi-account-group",
            // url: "/add-user-admin",
        },

    ];

    return (
        <>
            <TabMenu model={items} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
            <div
                style={{
                    display: "inline-block",
                    justifyContent: "left",
                    alignItems: "center",
                    height: "30vh",
                    width: "100%",
                }}
            >
                {activeIndex === 0 ?
                    <InputFormatRBAC/> :
                    <OutputFormatRBAC/>

                }

            </div>


        </>
    );
};
export default ManageRBAC;
